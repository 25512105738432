export default () => {
    let result = {}

    result.electricity = require('../../json/roles-hub/electricity')
    result.newSubdivision = require('../../json/roles-hub/subdivision.json')
    result.onsiteGeneration = require('../../json/roles-hub/onsite-generation.json')
    result.alteration = require('../../json/roles-hub/alteration.json')
    result.bts = require('../../json/roles-hub/temporary.json')
    result.project = require('../../json/roles-hub/project.json')
    result.ballparkEstimate = require('../../json/roles-hub/ballpark-estimate.json')
    result.permanentDisconnection = require('../../json/roles-hub/permanent-disconnection.json')
    result.safetyDisconnection = require('../../json/roles-hub/safety-disconnection.json')

    return result
}
