import React, {Component} from "react"
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import Header from "../../common/header"
import ThumbsUp from "../../../assets/svgs/complete/thumbs-up.svg"
import IconTick from "../../../assets/svgs/complete/icon-tick-completed.svg"
import Link from "../../../components/common/link"
import Button from "../../common/button"
import existsGet from "../../../helpers/exists-get"
import getCheckedFields from "../../../helpers/get-checked-fields"
import createNewOrderStore from "../../../store/create-new-order-store"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        contacts: existsGet(state, 'contacts', null),
        roles: existsGet(state, 'roles', null),
        content: {
            inverterManufacturers: existsGet(state, 'content.inverterManufacturers', []),
        },
        prerequisites: existsGet(state, 'prerequisites', null),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
        },
        savedState: existsGet(state, 'savedState', null)
    }
}

class Complete extends Component {
    constructor(props){
        super(props)
    }

    handleStartNewOrder = () => {
        const newStore = createNewOrderStore(this.props)
        this.props.replaceStore(newStore)
    }

    handleReturnToNorthpower = () => {
        window.open(links.northpower)
    }

    render() {
        const hasConnections = getCheckedFields(this.props.connections).length > 0

        return (
            <div id="complete">
                <Header
                    title={"Thanks, "+this.props.contacts['0'].firstName+'!'}
                />

                <div className="section">
                    <div className="container single-col">
                        <div className="submit-icon-container" style={{backgroundColor: 'transparent'}}>
                            <IconTick />
                        </div>
                        <div className="wrapper">
                            <p>We’ve received all of the information you have given us and your request has been generated in our system.</p>
                            {hasConnections ?
                               <>
                                   <p>You’ll be emailed in a few minutes with information about your application, including a reference number to use if you need to contact us about your job.</p>
                                   <p>If you haven’t received your email within 10 minutes, please check your junk or spam email folder. If the email is still lost please contact us on <b>0800 667 847</b> or via the <Link text='Northpower website' link={links.contact} />.</p>
                               </>
                            : null}
                            <p>If you are finished you can just close this screen. Alternatively you can start another transaction which will retain your contact information using the button below.</p>
                            <div className="button-wrapper">
                                <div>
                                    <Button text="Start New Order" btnType="btn-primary" action={this.handleStartNewOrder} />
                                    <Button text="Return to Northpower" btnType="btn-primary" action={this.handleReturnToNorthpower} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Complete)
