import React from 'react'
import Link from "../../components/common/link"
import getPriceList from "../../helpers/get-price-list"
import getOnsiteGenerationPriceRows from "../../helpers/get-onsite-generation-price-rows"

const links = require('../../json/links/links.json')
const priceList = getPriceList()

export default (props = null) => {
    return {
        "connections": {
            "electricity": {
                "label": "New Electricity Connection",
                "leadNotice": <>
                    <p>If power is available at the boundary this service takes around 10 working days.</p>
                    <p><b>Please note</b> – this is an indication of when you will require the connection to be livened
                        only – <b>this is not a booking.</b></p>
                    <p>We will be in touch with more information when your application is submitted.</p>
                    <span><b>If your request is urgent please call us on 0800 667 847.</b></span>
                </>,
                "leadTime": 10,
                "alerts": [
                    {
                        text: <div>
                            <span>Do you need a temporary electricity supply while your property is under construction?</span>
                        </div>
                    }
                ],
                "helperContent": <>
                    <span>New electricity connection for residential, commercial or industrial premise.</span>
                    <span>Do you already have a builders supply on the property? If so, please call us on 0800 667 847 to get
this changed to a new permanent supply.</span>
                </>,
                "paymentTableSettings": {
                    table: {
                        classes: 'table table-striped'
                    },
                    tableHeaders: {
                        classes: 'table-dark',
                        headers: [
                            'Fees',
                            ''
                        ]
                    },
                    tableRows: {
                        rows: [
                            ['Application Fee', '$' + priceList.electricity]
                        ]
                    }
                }
            },
            "bts": {
                "label": "Builders Temporary Supply",
                "leadNotice": <>
                    <p>If power is available at the boundary this service takes around 10 working days.</p>
                    <p><b>Please note</b> – this is an indication of when you will require the connection to be livened
                        only – <b>this is not a booking.</b></p>
                    <p>We will be in touch with more information when your application is submitted.</p>
                    <span><b>If your request is urgent please call us on 0800 667 847.</b></span>
                </>,
                "leadTime": 10,
                "alerts": [
                    {
                        text: <div>
                            <p><b>Will you need a permanent connection when your build project is completed?</b></p>
                            <span><b>If yes – please order a New Electricity connection, builders temporary application fee is free if you order at the same time as your permanent connection.</b></span>
                        </div>
                    },
                    {
                        text: <div>
                            <p>
                                A Builders Temporary Supply (BTS) is permitted for a period of up to 12 months, after
                                which time the connection must either be moved to a permanent structure or
                                decommissioned. BTS connections are permitted solely for building purposes, not for
                                recreational or any other purposes. BTS cannot be used to for connections for caravans,
                                cabins or newly constructed dwellings.
                            </p>
                            <span><b>If you would like a caravan or cabin connection please select New Electricity Connection from the Service Menu.</b></span>
                        </div>
                    }
                ],
                "paymentTableSettings": props === null || typeof props.connections === 'undefined' || !props.connections.electricityIsChecked ? {
                    table: {
                        classes: 'table table-striped'
                    },
                    tableHeaders: {
                        classes: 'table-dark',
                        headers: [
                            'Fees',
                            ''
                        ]
                    },
                    tableRows: {
                        rows: [
                            ['Application Fee',  '$' + priceList.ballparkEstimate]
                        ]
                    }
                } : null
            },
            "alteration": {
                "label": "Alteration to Existing Connection",
                "leadNotice": <>
                    <p>This service takes around 10 working days.</p>
                    <span><b>If your request is urgent please call us on 0800 667 847.</b></span>
                </>,
                "leadTime": 10,
                "helperContent": <>
                    <span>Alteration to existing electricity connection for residential, commercial or industrial premise.</span>
                </>,
                "paymentTableSettings": {
                    table: {
                        classes: 'table table-striped mb-0'
                    },
                    tableHeaders: {
                        classes: 'table-dark',
                        headers: [
                            'Fees',
                            ''
                        ]
                    },
                    tableRows: {
                        rows: [
                            ['Application Fee', '$' + priceList.alteration]
                        ]
                    }
                },
                "alerts": [
                    {
                        text: '' // required for paymentTableSettings to work
                    }
                ]
            },
            "onsiteGeneration": {
                "label": "Alternative Energy (Solar/Wind/Battery) Connection",
                "leadNotice": <>
                    <p>This service takes around 10 working days.</p>
                    <span><b>If your request is urgent please call us on 0800 667 847.</b></span>
                </>,
                "leadTime": 10,
                "paymentTableSettings": {
                    table: {
                        classes: 'table table-striped mb-0'
                    },
                    tableHeaders: {
                        classes: 'table-dark',
                        headers: [
                            'Fees',
                            ''
                        ]
                    },
                    tableRows: {
                        rows: getOnsiteGenerationPriceRows(priceList)
                    }
                },
                "alerts": [
                    {
                        text: '' // required for paymentTableSettings to work
                    }
                ]
            },
            "newSubdivision": {
                "label": "New Subdivision",
                "leadNotice": <>
                    <p>This service takes around 10 working days. Please upload your subdivision plans at the end of
                        this process and we will be in touch.</p>
                    <span><b>If your request is urgent please call us on 0800 667 847.</b></span>
                </>,
                "leadTime": 10,
                "alerts": [
                    {
                        text: <div>
                            <p>As well as arranging your electricity connection, Northpower will contact you to discuss fibre options for your subdivision.</p>
                            <span>If you already have a Northpower issued Resource consent requirements letter, please email us at <a
                                href="mailto:consents@northpower.com">consents@northpower.com</a> with your reference number and state whether you require a quote or clearance letter.</span>
                        </div>
                    }
                ],
                "paymentTableSettings": {
                    table: {
                        classes: 'table table-striped'
                    },
                    tableHeaders: {
                        classes: 'table-dark',
                        headers: [
                            'Fees',
                            ''
                        ]
                    },
                    tableRows: {
                        rows: [
                            ['Application Fee', '$' + priceList.newSubdivision]
                        ]
                    }
                }
            },
            "project": {
                "label": "Projects that affect Northpower Network Infrastructure",
                "alerts": [
                    {
                        text: <div>
                            <span>Dependent on the requirements of the project you are completing this application for, timeframes for assessment and response should be expected to take 4 working weeks.</span>
                        </div>
                    }
                ],
                "helperContent": <>
                    <span>New or existing network reticulation and equipment.</span>
                    <span>Examples include roading projects, streetlights, development projects affecting infrastructure, Fibre alterations, Council or Government based tenders.</span>
                </>
            },
            "ballparkEstimate": {
                "label": "Ball Park Estimate",
                "leadNotice": null,
                "leadTime": null,
                "alerts": [
                    {
                        text: <div>
                            <span>This option is for a ballpark figure for the following situations:</span>
                            <ul className='mb-0 mt-1'>
                                <li className='ml-2'>Power to the boundary for a single land parcel, without an existing power supply.</li>
                                <li className='ml-2'>Adding an additional connection to your property.</li>
                            </ul>
                        </div>
                    },
                    {
                        text: <div>
                            <p>
                                Ballparks are offered as guidance only prior to a land parcel purchase or for early planning to seek a
                                power supply to the boundary of the single land parcel in the future.
                            </p>
                            <p className='mb-0'>
                                All other enquires for multiple land parcels (subdivisions), alterations to supply and electrical
                                connection works must follow the new connection, alteration to existing or subdivision options
                                offered.
                            </p>
                        </div>
                    }
                ],
                "paymentTableSettings": {
                    table: {
                        classes: 'table table-striped'
                    },
                    tableHeaders: {
                        classes: 'table-dark',
                        headers: [
                            'Fees',
                            ''
                        ]
                    },
                    tableRows: {
                        rows: [
                            ['Ball Park Estimate',  '$' + priceList.ballParkEstimate]
                        ]
                    }
                }
            },
        },
        "services": {
            "generalEnquiry": {
                "label": "General Enquiry"
            },
            "fibre": {
                "label": "Can I Get Fibre?",
                "alerts": [
                    {
                        text: <div>
                            <span>Visit the <Link text="Northpower website" link={links.fibre}/> to check availability and register your interest.</span>
                        </div>
                    }
                ]
            },
            "safetyDisconnection": {
                "label": "Safety (Temporary) Disconnection",
                "leadNotice": <>
                    <p>This service takes 2 working days.</p>
                    <span><b>If your request is urgent please call us on 0800 10 40 40. A charge will apply for urgent service.</b></span>
                </>,
                "leadTime": 2
            },
            "permanentDisconnection": {
                "label": "Permanent Disconnection / Decommissions",
                "alerts": [
                    {
                        text: <div>
                            <p>Northpower will co-ordinate physical disconnection of your redundant power supply from
                                the Northpower network by a network approved contractor, and the recovery of retail
                                metering with your electricity retailer.</p>
                            <p>Any costs associated with either the physical disconnection or the metering recovery will
                                be by direct agreement between you and your selected contractor and retailer. No costs
                                will be directed to, or from Northpower.</p>
                        </div>
                    }
                ]
            },
            "vegetationControl": {
                "label": "Vegetation Control Application",
                "leadNotice": <>
                    <p>This service takes 2 working days.</p>
                    <span><b>If your request is urgent please call us on 0800 10 40 40. A charge will apply for urgent service.</b></span>
                </>,
                "leadTime": 2
            },
            "cableLocation": {
                "label": "Cable Location Request",
                "leadNotice": <>
                    <p>This service takes 2 working days.</p>
                    <span><b>If your request is urgent please call us on 0800 10 40 40. A charge will apply for urgent service.</b></span>
                </>,
                "leadTime": 2
            },
            "closeApproachConsent": {
                "label": "Working Near Lines Application",
                "leadNotice": <>
                    <p>This service takes 2 working days.</p>
                    <span><b>If your request is urgent please call us on 0800 10 40 40. A charge will apply for urgent service.</b></span>
                </>,
                "leadTime": 2
            },
            "highLoad": {
                "label": "High Load Transport Request",
                "leadNotice": <><p>This service has a 2 day lead time.</p><b>If your request is urgent please call us on
                    0800 10 40 40. A charge will apply for urgent service.</b></>,
                "leadTime": 2,
                "alerts": [
                    {
                        text: <div>
                            <span>You'll need to <Link text="download our High Load Request application form"
                                                       link={links.highLoad}/>. Once completed you can email it to <a
                                href="mailto:dispatch@northpower.com">dispatch@northpower.com</a> for service.</span>
                        </div>
                    }
                ]
            }
        }
    }
}
