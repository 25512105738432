import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import Alert from '../alert/alert'
import removeItemsFromArray from "../../../helpers/remove-items-from-array"
import getValidationRuleValue from "../../../helpers/get-validation-rule-value"

class ValidationAlert extends PureComponent {

    getValidationAlert() {
        const {validationErrors, validationRules} = this.props

        if (validationErrors.includes('required'))
            return this.props.validationMessages.required

        if (validationErrors.includes('requiredIf'))
            return this.props.validationMessages.requiredIf || this.props.validationMessages.required

        const remainingRules = removeItemsFromArray(validationErrors, ['required', 'requiredIf'])
        if (remainingRules.length === 0)
            return

        const validationMessage = this.props.validationMessages[remainingRules[0]]

        if (remainingRules.includes('min'))
        {
            return validationMessage.replace('{int}', getValidationRuleValue('min', validationRules))
        }
        else if (remainingRules.includes('max'))
        {
            return validationMessage.replace('{int}', getValidationRuleValue('max', validationRules))
        }
        else if (remainingRules.includes('maxValue'))
        {
            return validationMessage.replace('{int}', getValidationRuleValue('maxValue', validationRules))
        }
        else if (remainingRules.includes('minNumber'))
        {
            return validationMessage.replace('{int}', getValidationRuleValue('minNumber', validationRules))
        }
        else if (remainingRules.includes('maxNumber'))
        {
            return validationMessage.replace('{int}', getValidationRuleValue('maxNumber', validationRules))
        }
        else if (remainingRules.includes('maxNumericChars'))
        {
            return validationMessage.replace('{int}', getValidationRuleValue('maxNumericChars', validationRules))
        }
        else if (remainingRules.includes('maxAlphabeticalChars'))
        {
            return validationMessage.replace('{int}', getValidationRuleValue('maxAlphabeticalChars', validationRules))
        }

        return validationMessage
    }

    render(){
        const text = this.getValidationAlert()

        return (
            <Alert text={text} type="danger"/>
        )
    }
}

ValidationAlert.propTypes = {
    validationErrors: PropTypes.array.isRequired,
    validationMessages: PropTypes.object.isRequired
}

export default ValidationAlert
