export default (connections) => {
    const electricalConnectionTypes = [
        'electricity',
        'bts',
        'alteration',
        'newSubdivision',
        'onsiteGeneration',
        'ballparkEstimate',
        'permanentDisconnection'
    ]

    if (Array.isArray(connections)) {
        return electricalConnectionTypes.some(type => connections.includes(type))
    }

    return electricalConnectionTypes.some(type =>
        connections[type] || connections[type + 'IsChecked']
    )
}
