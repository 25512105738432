import getRoleSettings from "./get-role-settings"
const rolesSettings = getRoleSettings()

export default (connectionTypes) => {
    let result = []
    connectionTypes.map((connectionType) => {
        if (rolesSettings[connectionType]?.mandatory) {
            const mandatoryRoles = [...rolesSettings[connectionType].mandatory]
            mandatoryRoles.map(mandatoryRole => result.push(mandatoryRole))
        }
    })

    return [...new Set([...result])]
}
