import React, {Component} from 'react'
import {connect} from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import doesHaveElectricalConnectionChecked from "../../../helpers/does-have-electrical-connection-checked"
import Navigation from "../../common/navigation"
import Alert from "../../common/alert"
import Input from "../../common/input/input"
import Dropdown from "../../common/dropdown"
import Header from "../../common/header"
import Link from "../../../components/common/link"
import MandatoryText from "../../common/mandatory-text/mandatory-text"
import MandatoryIndicator from "../../common/mandatory-indicator"
import Tooltip from "../../common/tooltip"
import getIcpNumberHelperText from "../../../helpers/get-icp-number-helper-text"
import IcpNumber from "../../common/icp-number"
import shouldPreventNextIcpTriggerNavigation from "../../../helpers/should-prevent-next-icp-trigger-navigation"
import getCheckedFields from "../../../helpers/get-checked-fields"
import convertArrayToDropdownFormat from "../../../helpers/convert-array-to-dropdown-format"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        electricity: {
            retailer: existsGet(state, 'electricity.retailer', null),
            electricityRetailerCustomerNumber: existsGet(state, 'electricity.electricityRetailerCustomerNumber', ''),
            icpNumber: existsGet(state, 'electricity.icpNumber', ''),
            icpNumberRetain1: existsGet(state, 'electricity.icpNumberRetain1', ''),
            icpNumberRetain2: existsGet(state, 'electricity.icpNumberRetain2', ''),
            icpNumberRetain3: existsGet(state, 'electricity.icpNumberRetain3', ''),
        },
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked')
        },
        services: {
            generalEnquiryIsChecked: existsGet(state, 'services.generalEnquiryIsChecked'),
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
        },
        content: {
            preferredRetailerPicklist: convertArrayToDropdownFormat(state.content.preferredRetailerPicklist, 'name')
        },        // start icp-number requirements
        icpNumbersApproved: existsGet(state, 'icpNumbersApproved', []),
        icpNumbersDenied: existsGet(state, 'icpNumbersDenied', []),
        icpNumberPendingApprovalLatest: existsGet(state, 'icpNumberPendingApprovalLatest', [])
        // end icp-number requirements
    }
}

const validationMessages = {
    electricityRetailer: {
        'required': 'Please select your electricity retailer.'
    },
    icpNumberRetain: {
        'retainUnique': 'ICP numbers to retain must be unique',
        'retainDiffFromDisconnect': 'Cannot be the same as the ICP to disconnect'
    }
}

const content = require('../../../json/content/content.json')

class Retailer extends Component {
    constructor(props) {
        super(props)

        // Get checked fields from connections
        const connectionFields = getCheckedFields(this.props.connections)
        // Get checked fields from services
        const serviceFields = getCheckedFields(this.props.services)
        // Merge both arrays into checkedFields, removing any duplicates
        this.checkedFields = [...new Set([...connectionFields, ...serviceFields])]

        this.validationRules = this.getValidationRules()

        this.validationErrorFields = {
            electricity_retailerErrors: [],
            electricity_icpNumberErrors: [],
            electricity_icpNumberRetain1Errors: [],
            electricity_icpNumberRetain2Errors: [],
            electricity_icpNumberRetain3Errors: []
        }

        this.state = {
            ...this.validationErrorFields,
            render: false,
            // start icp-number requirements
            loadingForward: false,
            triggerIcpValidation: false,
            pendingIcpTriggerNavigation: false
            // end icp-number requirements
        }

        // start icp-number requirements
        this.preventNextIcpTriggerNavigation = false
        this.direction = 'next'
        // end icp-number requirements
    }

    isDecommission = () => {
        return typeof this.props.services.permanentDisconnectionIsChecked !== 'undefined' && this.props.services.permanentDisconnectionIsChecked
    }

    componentDidMount() {
        const retailer = this.props.electricity.retailer
        if (retailer) {
            const hasValidRetailer = this.props.content.preferredRetailerPicklist.some(item => item.value === retailer)
            if (!hasValidRetailer) {
                this.props.updateStoreValue('electricity.retailer', null)
            }
        }
    }

    componentDidUpdate(prevProps) {
        // start icp-number requirements
        if (this.state.loadingForward) {
            const changeableValues = [
                'electricity.icpNumber',
                'electricity.retailer',
                'electricity.electricityRetailerCustomerNumber',
                'electricity.icpNumberRetain1',
                'electricity.icpNumberRetain2',
                'electricity.icpNumberRetain3',
            ]

            if (shouldPreventNextIcpTriggerNavigation(changeableValues, prevProps, this.props)) {
                this.preventNextIcpTriggerNavigation = true
            }
        }

        if (
            !this.preventNextIcpTriggerNavigation &&
            this.state.pendingIcpTriggerNavigation &&
            prevProps.icpNumbersApproved.length !== this.props.icpNumbersApproved.length
        ) {
            this.setState({pendingIcpTriggerNavigation: false}, () => {
                this.handleNavigation(this.direction)
            })
        }

        if (this.state.pendingIcpTriggerNavigation && this.preventNextIcpTriggerNavigation) {
            this.setState({pendingIcpTriggerNavigation: false})
            this.preventNextIcpTriggerNavigation = false
        }
        // end icp-number requirements
    }

    getValidationRules = () => {
        let result = {}

        if (doesHaveElectricalConnectionChecked(this.checkedFields)) {
            result.electricity = {
                retailer: ['required']
            }
        }

        if (this.shouldShowIcpNumber(this.checkedFields)) {

            const retainIcpValidationRules = [{
                validate: (value, props) => {
                    const icpNumber = props.electricity.icpNumber
                    return !value || !icpNumber || value.trim() === '' || icpNumber.trim() === '' || value !== icpNumber
                },
                key: 'retainDiffFromDisconnect'
            }, {
                validate: (value, props) => {
                    if (!value || value.trim() === '') {
                        return true
                    }
                    const allRetainIcps = [
                        props.electricity.icpNumberRetain1,
                        props.electricity.icpNumberRetain2,
                        props.electricity.icpNumberRetain3
                    ].filter(icp => icp && icp.trim() !== '')
                    return allRetainIcps.filter(icp => icp === value).length <= 1
                },
                key: 'retainUnique'            }]

            result.electricity = {
                ...result.electricity,
                icpNumber: [ this.icpNumberRequired() ? 'required' : 'optional' ],
                ...(this.isDecommission() ? {
                    icpNumberRetain1: retainIcpValidationRules,
                    icpNumberRetain2: retainIcpValidationRules,
                    icpNumberRetain3: retainIcpValidationRules
                } : {})
            }
        }

        return result
    }

    // start icp-number requirements -- note addition of callback
    setParentState = (obj, callback = null) => {
        this.setState(obj, callback)
    }
    // end icp-number requirements

    shouldShowIcpNumber = () => {
        return this.props.connections.alterationIsChecked || this.props.connections.onsiteGenerationIsChecked || this.props.services.permanentDisconnectionIsChecked
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () => {
        const passedParentValidation = this.validate()

        if (this.shouldShowIcpNumber()) {
            // Trigger validation for all ICP fields
            this.setState({
                triggerIcpValidation: true
            }, () => {
                // Reset trigger after validation runs
                this.setState({triggerIcpValidation: false})
            })
        }

        // start icp-number requirements
        if (
            this.shouldShowIcpNumber() &&
            (this.props.electricity.icpNumber.length > 0 || this.icpNumberRequired()) &&
            !this.props.icpNumbersApproved.includes(this.props.electricity.icpNumber)
        ) {
            this.setState({triggerIcpValidation: true})
            this.props.removeProgress({'path': '/temporary-disconnection'})
            return false
        }
        // end icp-number requirements

        if (!passedParentValidation)
            return false

        // start icp-number requirements
        if (this.direction === 'summary')
            return '/summary'
        // end icp-number requirements

        return '/approved-contractor'
    }

    validateAndNavigate = () => {
        const location = this.validateAndGetNextLocation()
        if (location) {
            this.props.addProgress({'path': '/retailer'})
            this.props.updateLocation(location, null, this.direction)

            return true
        }

        this.props.removeProgress({'path': '/retailer'})

        return false
    }

    handleNavigation = (direction) => {
        // start icp-number requirements
        this.direction = direction

        if (this.direction === 'next' || this.direction === 'summary' || this.direction === 'forward') {
            return this.validateAndNavigate()
        }
        // end icp-number requirements

        if (this.direction === 'back') {
            this.props.removeProgress({'path': '/retailer'})

            this.props.updateLocation('/roles-hub', null, 'back')

            return true
        }

        return false
    }

    icpNumberRequired = () => {
        const checkedFields = getCheckedFields(this.props.connections)
        // icpNumber is conditional on shouldShowIcpNumber() which currently only checks for alteration or onsiteGeneration, onsiteGeneration has icpNumber as optional.
        // permanentDisconnection also requires an ICP
        return this.checkedFields.includes('alteration') || this.checkedFields.includes('permanentDisconnection')

    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="retailer" className="section-margin-bottom">
                <Header
                    title="Retailer"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary}
                            handleNavigation={this.handleNavigation} loadingForward={this.state.loadingForward}/>

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger"/>
                            </div>
                            : null}

                        <Alert type="description" text={this.isDecommission() ?
                            <div>
                                To begin decommissioning we require information about your electricity retailer.
                            </div> :
                            <div>
                                To help speed up your connection it's helpful if we have information about your
                                retailer. If you do not have a retailer you can use the <Link text='Powerswitch website'
                                                                                              link={links.whatsMyNumber}/> to
                                compare providers.
                            </div>
                        }/>

                        <div className="wrapper">

                            <MandatoryText/>
                            {doesHaveElectricalConnectionChecked(this.checkedFields) ?
                                <>
                                    <Dropdown
                                        label={<>Electricity <MandatoryIndicator>Retailer</MandatoryIndicator></>}
                                        keyString={"electricity.retailer"}
                                        parentErrorKey="electricity_retailerErrors"
                                        updateStoreValue={this.props.updateStoreValue}
                                        selectedOption={this.props.electricity.retailer}
                                        options={this.props.content.preferredRetailerPicklist}
                                        isSearchable={true}
                                        validationMessages={validationMessages.electricityRetailer}
                                        validationErrorsFromParent={this.state.electricity_retailerErrors}
                                        validationRules={this.validationRules.electricity.retailer}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Electricity Retailer Customer Number"
                                        keyString={"electricity.electricityRetailerCustomerNumber"}
                                        value={this.props.electricity.electricityRetailerCustomerNumber}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />
                                </>
                                : null}

                            {this.shouldShowIcpNumber() ?
                                <>
                                    <IcpNumber
                                        label={<>{this.isDecommission() ? "ICP Number of supply to be permanently disconnected" : "ICP Number"}{this.icpNumberRequired() ?
                                            <MandatoryIndicator/> : null}<Tooltip content={<p>
                                            {getIcpNumberHelperText()}
                                        </p>}/></>}
                                        keyString={"electricity.icpNumber"}
                                        parentErrorKey="electricity_icpNumberErrors"
                                        value={this.props.electricity.icpNumber}
                                        required={this.icpNumberRequired()}
                                        validationErrorsFromParent={this.state.electricity_icpNumberErrors}
                                        validationRules={this.validationRules.electricity.icpNumber}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                        parentHasErrors={showValidationError}
                                        loadingForward={this.state.loadingForward}
                                        triggerIcpValidation={this.state.triggerIcpValidation}
                                        icpNumbersApproved={this.props.icpNumbersApproved}
                                        icpNumbersDenied={this.props.icpNumbersDenied}
                                        icpNumberPendingApprovalLatest={this.props.icpNumberPendingApprovalLatest}
                                    />
                                    {this.isDecommission() &&
                                        <>
                                            <Alert type="description">
                                                <div>Are there any other power supplies on the same land parcel or building that <b>will remain</b> connected?
                                                </div>
                                                <div>If so, please provide the ICP numbers of the supplies to be retained (you can find these on your electricity bill).
                                                </div>
                                            </Alert>
                                            <IcpNumber
                                                label="ICP Number of supply to retain"
                                                keyString={"electricity.icpNumberRetain1"}
                                                parentErrorKey="electricity_icpNumberRetain1Errors"
                                                value={this.props.electricity.icpNumberRetain1}
                                                required={false}
                                                validationErrorsFromParent={this.state.electricity_icpNumberRetain1Errors}
                                                validationMessages={validationMessages.icpNumberRetain}
                                                validationRules={this.validationRules.electricity.icpNumberRetain1}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                                parentHasErrors={showValidationError}
                                                loadingForward={this.state.loadingForward}
                                                triggerIcpValidation={this.state.triggerIcpValidation}
                                                icpNumbersApproved={this.props.icpNumbersApproved}
                                                icpNumbersDenied={this.props.icpNumbersDenied}
                                                icpNumberPendingApprovalLatest={this.props.icpNumberPendingApprovalLatest}
                                            />
                                            <IcpNumber
                                                label="ICP Number of supply to retain"
                                                keyString={"electricity.icpNumberRetain2"}
                                                parentErrorKey="electricity_icpNumberRetain2Errors"
                                                value={this.props.electricity.icpNumberRetain2}
                                                required={false}
                                                validationErrorsFromParent={this.state.electricity_icpNumberRetain2Errors}
                                                validationMessages={validationMessages.icpNumberRetain}
                                                validationRules={this.validationRules.electricity.icpNumberRetain2}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                                parentHasErrors={showValidationError}
                                                loadingForward={this.state.loadingForward}
                                                triggerIcpValidation={this.state.triggerIcpValidation}
                                                icpNumbersApproved={this.props.icpNumbersApproved}
                                                icpNumbersDenied={this.props.icpNumbersDenied}
                                                icpNumberPendingApprovalLatest={this.props.icpNumberPendingApprovalLatest}
                                            />
                                            <IcpNumber
                                                label="ICP Number of supply to retain"
                                                keyString={"electricity.icpNumberRetain3"}
                                                parentErrorKey="electricity_icpNumberRetain3Errors"
                                                value={this.props.electricity.icpNumberRetain3}
                                                required={false}
                                                validationErrorsFromParent={this.state.electricity_icpNumberRetain3Errors}
                                                validationMessages={validationMessages.icpNumberRetain}
                                                validationRules={this.validationRules.electricity.icpNumberRetain3}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                                parentHasErrors={showValidationError}
                                                loadingForward={this.state.loadingForward}
                                                triggerIcpValidation={this.state.triggerIcpValidation}
                                                icpNumbersApproved={this.props.icpNumbersApproved}
                                                icpNumbersDenied={this.props.icpNumbersDenied}
                                                icpNumberPendingApprovalLatest={this.props.icpNumberPendingApprovalLatest}
                                            />
                                        </>
                                    }
                                </>
                                : null}
                        </div>
                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger"/>
                            </div>
                            : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation}
                                loadingForward={this.state.loadingForward}/>
                    : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(
    React.forwardRef((props, ref) => {
        return <Retailer ref={ref} {...props} />
    })
)
