import React, {Component} from 'react'
import {connect} from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import getCheckedFields from "../../../helpers/get-checked-fields"
import Navigation from "../../common/navigation"
import Alert from "../../common/alert"
import Dropdown from "../../common/dropdown"
import Header from "../../common/header"
import {getNextNonConnectionService} from "../../../services/router/non-connection-service"
import MandatoryText from "../../common/mandatory-text/mandatory-text"
import Modal from "../../common/modal"
import Link from "../../../components/common/link"
import TextButton from "../../common/text-button"
import Tick from "../../../assets/svgs/approved-contractor/tick.svg"
import keyStringToId from "../../../helpers/key-string-to-id"

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: {
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
        },
        propertyType: existsGet(state, 'propertyType', null),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
        },
        electricity: {
            networkApprovedContractor: existsGet(state, 'electricity.networkApprovedContractor', '')
        },
        content: {
            networkAgreedContractorMatrix: state.content.networkAgreedContractorMatrix
        },
    }
}

const validationMessages = {
    'electricity_networkApprovedContractor': {
        'required': 'Please select your network approved contractor.'
    }
}

const content = require('../../../json/content/content.json')
const links = require('../../../json/links/links.json')

class ApprovedContractor extends Component {
    constructor(props) {
        super(props)

        this.validationRules = {
            electricity: {
                networkApprovedContractor: ['required']
            }
        }

        this.validationErrorFields = {
            electricity_networkApprovedContractorErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            oneRequiredError: false,
            hasError: false,
            showModal: false
        }
    }

    isDecommission = () => {
        return typeof this.props.services.permanentDisconnectionIsChecked !== 'undefined' && this.props.services.permanentDisconnectionIsChecked
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward') {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back') {
            this.props.removeProgress({'path': '/approved-contractor'})
            this.props.updateLocation('/retailer', null, 'back')

            return true
        }

        if (direction === 'summary' && this.validate()) {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () => {
        const passedParentValidation = this.validate()
        if (!passedParentValidation) {
            this.props.removeProgress({'path': '/approved-contractor'})
            return false
        }

        this.props.addProgress({'path': '/approved-contractor'})

        const checkedConnections = getCheckedFields(this.props.connections)

        const caravanOrOnlyBTS = this.props.propertyType === 'Caravan' || (checkedConnections.length === 1 && checkedConnections[0] === 'bts')

        if ((checkedConnections.length === 1 || caravanOrOnlyBTS) && checkedConnections.includes('onsiteGeneration')) {
            return '/distributed-generation-capacity'
        }

        if (caravanOrOnlyBTS) {
            const nextNonConnectionService = getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)
            return nextNonConnectionService !== null ? nextNonConnectionService : '/other-information'
        }

        if (this.isDecommission()){
            return '/other-information'
        }

        return '/manual-capacity'
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location) {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    render() {

        const filteredContractors = this.props.content.networkAgreedContractorMatrix
            .filter(contractor => this.isDecommission() ?
                contractor.applicationTypes.includes('Permanent Disconnection') :
                contractor.applicationTypes.includes('New Connection'))
            .map(contractor => ({
                label: contractor.name,
                value: contractor.name
            }))
            .sort((a, b) => a.value.toString().localeCompare(b.value.toString()))
            .concat([{
                label: 'Unsure/Unknown',
                value: 'Unsure/Unknown'
            }]);

        return (
            <div id="approved-contractor" className="section-margin-bottom">
                <Header
                    title="Your chosen contractor"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary}
                            handleNavigation={this.handleNavigation}/>

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {this.state.oneRequiredError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger"/>
                            </div>
                            : null}

                        <Alert type="description"
                               text={this.isDecommission() ?
                                   <>
                                       <p>Only specifically approved contractors can undertake the physical
                                           disconnection of your redundant supply from the Northpower Network. If you
                                           are unsure, we can recommend an approved contractor in your area.
                                           <br /><br /><strong>Note</strong>: we will issue your contact details to the selected contractor. They will
                                           agree any costs associated with the physical disconnection of your redundant
                                           supply prior to the works commencing.</p>
                                   </> :
                                   <>
                                       <p>Only specifically approved contractors are able to connect new meters, and
                                           lines to poles or pillars.</p>
                                       <p>If you're unsure we can recommend an approved
                                           contractor in your area.</p>
                                   </>

                               }/>

                        <div className="wrapper">
                            <MandatoryText/>

                            <Dropdown
                                label="Network approved contractor"
                                mandatoryIndicator={true}
                                keyString={"electricity.networkApprovedContractor"}
                                parentErrorKey="electricity_networkApprovedContractorErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                selectedOption={this.props.electricity.networkApprovedContractor}
                                options={filteredContractors}
                                isSearchable={true}
                                validationMessages={validationMessages.electricity_networkApprovedContractor}
                                validationErrorsFromParent={this.state.electricity_networkApprovedContractorErrors}
                                validationRules={this.validationRules.electricity.networkApprovedContractor}
                                setParentState={this.setParentState}
                            />

                            <Modal
                                title="Network Approved Contractors"
                                className="network-approved-contractor-modal"
                                component={<div>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <tbody>
                                            <tr key='header'>
                                                <th>Company</th>
                                                <th>Inspections</th>
                                                <th>Liven to Network</th>
                                                <th>Meter Hanging</th>
                                                <th>Decommission</th>
                                            </tr>
                                            {this.props.content.networkAgreedContractorMatrix.map(contractor => (
                                                <tr key={keyStringToId(contractor.name)}>
                                                    <td>
                                                        <Link text={contractor.name} link={contractor.website} />
                                                        <span>{contractor.phone}</span>
                                                        <a href={`mailto:${contractor.email}`}>{contractor.email}</a>
                                                    </td>
                                                    <td className="tick">{contractor.capabilities.includes('Inspections') && <Tick />}</td>
                                                    <td className="tick">{contractor.capabilities.includes('Liven to Network') && <Tick />}</td>
                                                    <td className="tick">{contractor.capabilities.includes('Meter Hanging') && <Tick />}</td>
                                                    <td className="tick">{contractor.capabilities.includes('Decommission') && <Tick />}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                show={this.state.showModal}
                                setParentState={this.setParentState}
                                closeAction={() => this.setState({showModal: false})}
                            />

                            <TextButton color="orange" text="Help me choose an approved contractor"
                                        onClick={() => this.setState({showModal: !this.state.showModal})}/>
                        </div>

                        {this.props.showBottomNavigation && this.state.oneRequiredError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger"/>
                            </div>
                            : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation}/>
                    : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(
    React.forwardRef((props, ref) => {
        return <ApprovedContractor ref={ref} {...props} />
    })
)
