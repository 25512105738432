import React, {Component} from 'react'
import ValidationAlert from "../../../components/common/validation-alert"
import Checkbox from "../../common/checkbox"
import Alert from "../../common/alert"
import Link from "../../common/link"
import MandatoryIndicator from "../../common/mandatory-indicator/mandatory-indicator"
import TextButton from "../../common/text-button"
import defaultReduxState from '../../../store/default-redux-state'

/* global google */

const links = require('../../../json/links/links.json')
const fibreExtents = require('../../../json/geo/fibreExtentsWGS84.json')

class GoogleAddress extends Component {
    constructor(props) {
        super(props)

        this.autocompleteInputRef = React.createRef()
        this.dummyFieldForPlacesRef = React.createRef()
        this.autocomplete = null
        this.autocompleteListener = null
        this.placeServices = null
        this.geocoder = null
        this.firstKeyPressAfterFocusOrReposition = false

        this.state = {
            valueChangedOnField: false,
            ignoreNextLatLngChange: false
        }
    }

    componentDidMount() {
        this.autocompleteInputRef.current.value = this.props.googleServiceAddress

        this.placeServices = new google.maps.places.PlacesService(this.dummyFieldForPlacesRef.current)
        this.geocoder = new google.maps.Geocoder(this.dummyFieldForPlacesRef.current)

        if (this.props.serviceAddress !== defaultReduxState.serviceAddress)
        {
            this.initializeAutocomplete()
        }

        // This is for if the user entered their address into the wrong service, then went back to the correct one - without this the fibre lookup would never happen
        if
        (
            this.props.doFibreLookup && this.props.fibreStatus === null &&
            typeof this.props.serviceAddress.geolocationLatitude !== 'undefined' &&
            this.props.serviceAddress.geolocationLatitude !== null &&
            typeof this.props.serviceAddress.geolocationLongitude !== 'undefined' &&
            this.props.serviceAddress.geolocationLongitude !== null
        )
        {
            this.fibreLookup(new google.maps.LatLng(this.props.serviceAddress.geolocationLatitude, this.props.serviceAddress.geolocationLongitude))
        }

        if (!this.props.doFibreLookup)
        {
            this.props.updateStoreValue('fibre.status', null)
        }

        document.getElementById("app").addEventListener('scroll', this.moveAutocomplete)
        window.addEventListener('resize', this.handleResize)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.isProject
        )
        {
            const lat = this.props.latLng.lat
            const lng = this.props.latLng.lng

            if (lat !== null && lng !== null && (prevProps.latLng.lat !== lat || prevProps.latLng.lng !== lng))
            {
                if (!this.state.ignoreNextLatLngChange)
                {
                    this.geocoder.geocode({location: {lat: lat, lng: lng}})
                    .then((response) => {
                        if (typeof response.results !== 'undefined' && typeof response.results[0] !== 'undefined') {
                            const firstResult = response.results[0]

                           if (typeof firstResult.address_components !== 'undefined' && typeof firstResult.formatted_address !== 'undefined')
                            {
                                this.autocompleteInputRef.current.value = firstResult.formatted_address.replace(', New Zealand', '')
                                this.storeAddress(firstResult.address_components)

                                this.props.updateStoreValue('google.serviceAddress', this.autocompleteInputRef.current.value)
                                this.fibreLookup(new google.maps.LatLng(lat, lng))
                            }
                            else
                            {
                                console.info('bad firstResult')
                                console.info(firstResult)
                            }
                        }
                    })
                    .catch((e) => console.info("Geocoder failed due to: " + e))
                }
                else
                {
                    this.setState({ignoreNextLatLngChange: false})
                }
            }
        }
    }

    componentWillUnmount() {
        document.getElementById("app").removeEventListener('scroll', this.moveAutocomplete)
        window.removeEventListener('resize', this.handleResize)

        if (this.autocompleteListener !== null)
        {
            google.maps.event.removeListener(this.autocompleteListener)
            google.maps.event.clearInstanceListeners(this.autocomplete)
        }

        const pacContainer = document.getElementsByClassName("pac-container")[0]
        if (typeof pacContainer !== 'undefined')
        {
            pacContainer.parentNode.removeChild(pacContainer)
        }
    }

    handleResize = () => {
        if (!this.firstKeyPressAfterFocusOrReposition)
            this.firstKeyPressAfterFocusOrReposition = true
    }

    moveAutocomplete = () => {
        const pacContainer = document.getElementsByClassName("pac-container")[0]
        if (typeof pacContainer !== 'undefined' && pacContainer.style.display !== 'none')
        {
            const rect = this.autocompleteInputRef.current.getBoundingClientRect()
            const rectTop = rect.y || rect.top
            const rectHeight = rect.height
            const top = rectTop + rectHeight

            pacContainer.style.top = top+'px'
        }
    }

    initializeAutocomplete = () => {
        this.setState({valueChangedOnField: true}, () => {
            const bounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(process.env.BOUNDS_SW_LAT, process.env.BOUNDS_SW_LNG),
                new google.maps.LatLng(process.env.BOUNDS_NE_LAT, process.env.BOUNDS_NE_LNG)
            )

            this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInputRef.current, {
                "types": ["geocode"],
                bounds: bounds,
                strictBounds: true
            })

            this.autocompleteListener = this.autocomplete.addListener('place_changed', this.handlePlaceChanged)
        })
    }

    getAddressNumber = () => {
        let firstAddressValue = this.autocompleteInputRef.current.value.split(" ")[0]
        if (firstAddressValue.includes("/"))
        {
            return firstAddressValue.split("/")[1]
        }

        if (/\d/.test(firstAddressValue))
        {
            return firstAddressValue
        }

        return ''
    }

    storeAddress = (addressComponents) => {
        let address = Object.assign({}, defaultReduxState.serviceAddress)
        address.number = this.getAddressNumber()

        addressComponents.forEach((addressObject) => {
            if (addressObject.types.includes('subpremise'))
            {
                address.unit = addressObject.long_name
            }
            else if (addressObject.types.includes('route'))
            {
                address.street = addressObject.long_name
            }
            else if (addressObject.types.includes('sublocality_level_1'))
            {
                address.suburb = addressObject.long_name
            }
            else if (addressObject.types.includes('locality'))
            {
                address.city = addressObject.long_name
            }
            else if (addressObject.types.includes('postal_code'))
            {
                address.postcode = addressObject.long_name
            }
        })

        const updatedServiceAddressObject = Object.assign({}, address, {
            geolocationLatitude: typeof this.props.serviceAddress.geolocationLatitude !== 'undefined' ? this.props.serviceAddress.geolocationLatitude : null,
            geolocationLongitude: typeof this.props.serviceAddress.geolocationLongitude !== 'undefined' ? this.props.serviceAddress.geolocationLongitude : null
        })

        this.props.updateStoreValue('serviceAddress', updatedServiceAddressObject)
    }

    fibreLookup = (latLng) => {
        let fibreStatus = null

        const features = fibreExtents.features
        for (let i = 0; i < features.length; i++) {
            const feature = features[i]

            const coordinateObject = feature.geometry.coordinates[0]
            const polygon = new google.maps.Polygon({ paths: Object.keys(coordinateObject).map((key) => {
                    return {lat: coordinateObject[key][1], lng: coordinateObject[key][0]}
                })
            })

            if (google.maps.geometry.poly.containsLocation(latLng, polygon))
            {
                fibreStatus = feature['properties']['FBAR_STATUS'] === 'Service Ready' ? 'ready' : 'planned'
                break
            }
        }

        this.props.updateStoreValue('fibre.status', fibreStatus)

        if (this.props.readyPropertyIsChecked && (fibreStatus === null && !this.props.isProject))
        {
            this.props.updateStoreValue('fibre.readyPropertyIsChecked', false)
        }
    }

    handlePlaceChanged = () => {
        const place = this.autocomplete.getPlace()

        if (!place.address_components)
        {
            this.props.setParentState({'google_serviceAddressErrors': ['required']})

            return false
        }

        this.props.setParentState({
            'serviceAddress_numberErrors': [],
            'serviceAddress_unitErrors': [],
            'serviceAddress_streetErrors': [],
            'serviceAddress_suburbErrors': [],
            'serviceAddress_cityErrors': [],
            'serviceAddress_postcodeErrors': [],
            'google_serviceAddressErrors': []
        })

        this.props.updateStoreValue('google.serviceAddress', this.autocompleteInputRef.current.value || null)

        const request = {
            placeId: place.place_id,
            fields: ['geometry']
        }

        this.setState({ignoreNextLatLngChange: true}, () => {
            this.placeServices.getDetails(request,
                (place, status) => {
                    if (status === 'OK')
                    {
                        const latLng = {lat: place.geometry.location.lat(), lng: place.geometry.location.lng()}

                        this.props.updateStoreValue('serviceAddress', {
                            ...this.props.serviceAddress,
                            geolocationLatitude: latLng.lat,
                            geolocationLongitude: latLng.lng
                        })

                        if (this.props.doFibreLookup)
                        {
                            this.fibreLookup(place.geometry.location)
                        }
                        else
                        {
                            this.props.updateStoreValue('fibre.status', null)
                        }
                    }
                }
            )

            this.storeAddress(place.address_components)
        })
    }

    handleReposition = () => {
        const rect = this.autocompleteInputRef.current.getBoundingClientRect()
        const rectTop = rect.y || rect.top
        const rectHeight  = rect.height
        const rectBottom = rectTop + rectHeight
        const app = document.getElementById('app')
        const appRect = app.getBoundingClientRect()
        const appHeight = appRect.height
        const appScrollTop = app.scrollTop
        const inputPos = appHeight - rectBottom

        let positionToMove = 100
        if (this.state.valueChangedOnField && !this.props.showServiceAddressFields)
        {
            positionToMove = 150
        }

        if (inputPos <= positionToMove)
        {
            app.scrollTop = appScrollTop+(positionToMove-inputPos)
        }

        this.firstKeyPressAfterFocusOrReposition = false
    }

    handleOnTextChange = (e) => {
        const value = e.target.value

        if (this.firstKeyPressAfterFocusOrReposition)
        {
            this.handleReposition()
        }

        if (this.props.fibreStatus !== null && value.length === 0)
        {
            this.props.updateStoreValue('fibre.status', null)
        }

        if (!this.state.valueChangedOnField)
        {
            this.initializeAutocomplete()
        }
        else
        {
            this.resetServiceAddress(value)
        }
    }

    onInputFocus = () => {
        this.firstKeyPressAfterFocusOrReposition = true
    }

    resetServiceAddress = (value) => {
        if (this.props.googleServiceAddress !== null && value !== this.props.googleServiceAddress)
        {
            this.props.updateStoreValue('google.serviceAddress', null)

            if (!this.props.isProject)
                this.props.updateStoreValue('fibre.status', null)
        }
    }

    handleAddManually = () => {
        this.props.updateStore({
            serviceAddress: {
                addressManuallyAdded: true
            },
            showServiceAddressFields: true
        })
    }

    render(){
        const validationErrors = [...this.props.validationErrorsFromParent]

        let fibreText = {
            ready: <p>Fibre is available in your area. You can ready your property for fibre by having fibre ducting installed, this means you’ll be ready to connect once fibre is available. Read more in our Fibre FAQs <Link link={links.fibre} text='here'/> to find out more about our Ultrafast Fibre.</p>,
            planned: <p>Fibre is coming soon to your area. You can ready your property for fibre by having fibre ducting installed, this means you’ll be ready to connect once fibre is available. Read more in our Fibre FAQs <Link link={links.fibre} text='here' /> to find out more about our Ultrafast Fibre.</p>
        }

        let projectFibreText = null
        let fibreCheckboxLabel = "Yes, please ready my property for fibre."

        if (this.props.isProject === true)
        {
            projectFibreText = <p>While fibre may not be available in your area, you can indicate that you are interested below. You can ready your property for fibre by having fibre ducting installed, this means you’ll be ready to connect once fibre is available. Read more in our Fibre FAQs <Link link={links.fibre} text='here'/> to find out more about our Ultrafast Fibre.</p>
            fibreCheckboxLabel = "I am interested in getting fibre installed if it's available."
        }
        if(this.props.isDecommission === true)
        {
            fibreText = {
                ready: <p>You may have Fibre connected. Please lets us know if you need this disconnected as well.</p>
            }
            fibreCheckboxLabel = "Fibre disconnection required."
        }

        let className = "google-address-wrapper form-group"
        const hasValidationErrors = validationErrors.length > 0

        if (hasValidationErrors)
        {
            className += " error"
        }

        if (this.props.isProject === true)
        {
            className += ' google-address-wrapper--is-project'
        }

        return (
            <div className={className}>
                <label htmlFor="autocomplete"><span className='non-breaking'>{this.props.label}<MandatoryIndicator show={!this.props.showServiceAddressFields} /></span></label>
                {this.state.valueChangedOnField && !this.props.showServiceAddressFields && !this.props.isProject ? <TextButton className="cant-find-address-link" color="orange" text="Can't find the Address? Add Manually" onClick={this.handleAddManually} /> : null}
                <input onFocus={this.onInputFocus} className="form-control" ref={this.autocompleteInputRef} id="autocomplete" placeholder="Enter your address" type="text" onChange={this.handleOnTextChange} />
                {this.props.doFibreLookup && (this.props.fibreStatus !== null || (this.props.isProject && this.props.googleServiceAddress !== null)) ?
                    <Alert
                        text={!this.props.isProject ? fibreText[this.props.fibreStatus] : projectFibreText}
                        type='info'
                        checkbox={<Checkbox keyString={'fibre.readyPropertyIsChecked'} label={fibreCheckboxLabel} updateStoreValue={this.props.updateStoreValue} isChecked={this.props.readyPropertyIsChecked} />}
                        className="mt-2"
                    />
                : null}
                <div ref={this.dummyFieldForPlacesRef} />
                {hasValidationErrors ?
                    <ValidationAlert validationRules={this.props.validationRules} validationErrors={validationErrors} validationMessages={this.props.validationMessages} />
                : null}
            </div>
        )
    }
}

export default GoogleAddress
