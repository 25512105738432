import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import getCheckedFields from "../../../helpers/get-checked-fields"
import Alert from "../../common/alert"
import Checkbox from "../../common/checkbox"
import Navigation from "../../common/navigation"
import Header from "../../common/header"
import prerequisiteList from "../../../services/prerequisites/get-prerequisites"
import validator from "../../../services/validator/validator"
import hasCoordinates from "../../../helpers/has-coordinates"
import shouldShowPropertyType from "../../../helpers/should-show-property-type"
import StandardTable from "../../common/standard-table"
import getPriceList from "../../../helpers/get-price-list"
import getOnsiteGenerationPriceRows from "../../../helpers/get-onsite-generation-price-rows"
import windcaveA2ALogo from '../../../assets/images/windcave-a2a-logo.jpg'
import VisaMasterCardAmexLogo from '../../../assets/svgs/payments/visa-mastercard-amex-logo.svg'
import WindcaveLogo from '../../../assets/svgs/payments/windcave-logo.svg'
import hasError from "../../../helpers/has-error"
import Link from "../../common/link/link";
import links from "../../../json/links/links.json";

const priceList = getPriceList()

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        connections: existsGet(state, 'connections', null),
        services: existsGet(state, 'services', null),
        serviceAddress: existsGet(state, 'serviceAddress', null),
        prerequisitesIsChecked: existsGet(state, 'prerequisitesIsChecked', false),
    }
}

const validationMessages = {
    prerequisitesIsChecked: {
        'required': 'Please confirm that you have all the required information ready.'
    }
}

const validationRules = {
    prerequisitesIsChecked: ['required']
}

class Prerequisites extends Component {
    constructor(props) {
        super(props)

        // Get checked fields from connections
        const connectionFields = getCheckedFields(this.props.connections)
        // Get checked fields from services
        const serviceFields = getCheckedFields(this.props.services)
        // Merge both arrays into checkedFields, removing any duplicates
        this.checkedFields = [...new Set([...connectionFields, ...serviceFields])]

        const requiredPrerequisites = require('../../../json/prerequisites/required.json')

        this.relevantPrerequisites = []
        Object.keys(requiredPrerequisites).forEach((key) => {
            if (this.checkedFields.includes(key))
            {
                requiredPrerequisites[key].forEach((item) => {
                    if (!this.relevantPrerequisites.includes(item+'IsChecked'))
                    {
                        this.relevantPrerequisites.push(item+'IsChecked')
                    }
                })
            }
        })

        this.validationErrorFields = {
            prerequisitesIsCheckedErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            render: false
        }
    }

    isDecommission = () => {
        return typeof this.props.services.permanentDisconnectionIsChecked !== 'undefined' && this.props.services.permanentDisconnectionIsChecked
    }

    componentDidMount = () => {
        if (!this.state.render)
        {
            this.setState({render: true})
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!this.state.render && Object.keys(this.props.prerequisites).length > 0)
        {
            this.setState({render: true})
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () => {
        if (this.validate())
        {
            this.props.addProgress({'path': this.props.historyLocation})
            return '/roles-hub'
        }

        return false
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            const connectionsChecked = this.checkedFields

            if (!shouldShowPropertyType(connectionsChecked))
            {
                const newLocation = (this.props.serviceAddress !== null && this.props.serviceAddress.addressManuallyAdded) || !hasCoordinates(this.props.serviceAddress) ? '/name-and-address' : '/confirm-location'
                this.props.updateLocation(newLocation, null, 'back')

                return true
            }

            this.props.updateLocation('/property-type', null, 'back')

            return true
        }

        return false
    }

    requiresPayment = () => {
        const checkedFields = getCheckedFields(this.props.connections)
        return ['electricity', 'bts', 'alteration', 'newSubdivision', 'onsiteGeneration'].some(name => checkedFields.includes(name))
    }

    generateFeesTable = () => {
        const checkedFields = getCheckedFields(this.props.connections)

        if (!this.requiresPayment())
            return null

        let rows = []
        let total = 0

        if (checkedFields.includes('electricity'))
        {
            total += priceList.electricity
            rows.push(['New Electricity Connection', '$' + priceList.electricity])
        }

        if (checkedFields.includes('bts'))
        {
            if (!checkedFields.includes('electricity'))
                total += priceList.bts

            const btsPrice = !checkedFields.includes('electricity') ? priceList.bts : 0

            rows.push(['Builders Temporary Supply', '$' + btsPrice])
        }

        if (checkedFields.includes('alteration'))
        {
            total += priceList.alteration
            rows.push(['Alteration to existing Connection', '$' + priceList.alteration])
        }

        if (checkedFields.includes('newSubdivision'))
        {
            total += priceList.newSubdivision
            rows.push(['New Subdivision', '$' + priceList.newSubdivision])
        }

        if (checkedFields.includes('onsiteGeneration'))
        {
            total = 'TBC'
            // onsiteGeneration is standalone so overwriting rows is correct
            rows = getOnsiteGenerationPriceRows(priceList)
        }

        return <StandardTable
            uniqueTableName='prereqs'
            table={{
                classes: 'table table-bordered table-striped'
            }}
            tableHeaders={{
                classes: 'table-dark',
                headers: [
                    'Service',
                    'Fee'
                ]
            }}
            tableRows={{
                rows: rows
            }}
            tableFooters={{
                classes: 'table-dark',
                rows: ['Total', total !== 'TBC' ? '$'+total : 'TBC']
            }}
        />
    }

    generatePrerequisites = () => {
        const prerequisiteTextArray = []

        if (this.requiresPayment())
            prerequisiteTextArray.push(<><b>Your credit card or online banking information</b> - the above fee will be charged to enable you to submit this form.</>)

        this.relevantPrerequisites.forEach((relevantPrerequisite, i) => {
            let text = prerequisiteList[relevantPrerequisite.replace('IsChecked', '')]

            // Adjust text if required
            if (relevantPrerequisite.replace('IsChecked', '') === 'billPayer' && this.isDecommission()) {
                text = <><b>Bill payer contact details</b> - This information will be passed to your selected contractor and retailer to send notification of any costs associated with the works prior to commencement.</>
            }
            if (relevantPrerequisite.replace('IsChecked', '') === 'electricityRetailer' && this.isDecommission()) {
                text = <><b>Electricity retailer</b> - and ICP number (locate this on your electricity account statement).</>
            }

            prerequisiteTextArray.push(text)
        })

        return <ul>
            {prerequisiteTextArray.map((text, i) => {
                return <li key={'prereq_'+i}>{text}</li>
            })}
        </ul>
    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields)
        const errorMessage = validationMessages.prerequisitesIsChecked['required']

        return (
            <div id="prerequisites" className="section-margin-bottom">
                <Header
                    title={<>
                        Required information
                    </>}
                />

                <Navigation isTop={true} setParentState={this.setParentState} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={errorMessage} type="danger" />
                            </div>
                        : null}

                        <Alert type="description" text={<div>Please confirm that you have the required information ready.</div>} />

                        <div className="wrapper">
                            {this.state.render ?
                                <>
                                    {this.generateFeesTable()}
                                    {this.requiresPayment() ?
                                        <div className='payment-logos-wrapper'>
                                            <WindcaveLogo />
                                            <img className="windcave-logo-a2a" src={windcaveA2ALogo} alt="Windcave Account to Account Logo"/>
                                            <VisaMasterCardAmexLogo />
                                        </div>
                                    : null}
                                    {this.generatePrerequisites()}
                                </>
                            : null}
                            <Checkbox
                                label={"I have the required information ready"}
                                keyString="prerequisitesIsChecked"
                                updateStoreValue={this.props.updateStoreValue}
                                isChecked={this.props.prerequisitesIsChecked}
                                setParentState={this.setParentState}
                                parentErrorKey="prerequisitesIsCheckedErrors"
                                validationMessages={validationMessages.prerequisitesIsChecked}
                                validationErrorsFromParent={this.state.prerequisitesIsCheckedErrors}
                                validationRules={validationRules.prerequisitesIsChecked}
                            />
                            <p>
                                You will have the option to enter the contact information of other people involved in the connection process too (builder, on-site contact etc).
                            </p>
                            {this.checkedFields.includes('alteration') || this.checkedFields.includes('electricity') || this.checkedFields.includes('ballparkEstimate') ?
                                <div className='please-note-wrapper'>
                                    <span><b>Please note:</b></span>
                                    <p>As part of the approval process, Northpower will advise the connection point and phasing requirements. If the service cable is not run to the designated connection point or if the allocated phasing is not installed, the applicant may incur additional costs to rectify the situation and may be liable for any costs or expenses incurred by Northpower.</p>
                                </div>
                            : null}
                        </div>

                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={errorMessage} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <Prerequisites ref={ref} {...props} />
    })
)
