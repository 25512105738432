import React, {Component} from 'react'
import { connect } from "react-redux"
import validator from "../../../services/validator/validator"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import WelcomeCheckbox from "./welcome-checkbox"
import Alert from "../../common/alert"
import Header from "../../common/header"
import Link from "../../common/link"
import Navigation from "../../common/navigation"
import existsGet from "../../../helpers/exists-get"
import Checkbox from "../../common/checkbox"
import onlyHasSingleOfferingChecked from "../../../helpers/only-has-single-offering-checked"
import getServiceCheckboxesData from "../../../services/general/get-service-checkboxes-data"
import hasNonConnectionService from "../../../helpers/has-non-connection-service"
import serviceChanged from "../../../helpers/service-changed"

const links = require('../../../json/links/links.json')

const validationRules = {
    connections: {
        electricityIsChecked: ['oneRequired'],
        onsiteGenerationIsChecked: ['oneRequired'],
        newSubdivisionIsChecked: ['oneRequired'],
        alterationIsChecked: ['oneRequired'],
        btsIsChecked: ['oneRequired'],
        projectIsChecked: ['oneRequired'],
        ballparkEstimateIsChecked: ['oneRequired'],
    },
    services: {
        generalEnquiryIsChecked: ['oneRequired'],
        vegetationControlIsChecked: ['oneRequired'],
        safetyDisconnectionIsChecked: ['oneRequired'],
        permanentDisconnectionIsChecked: ['oneRequired'],
        closeApproachConsentIsChecked: ['oneRequired'],
        cableLocationIsChecked: ['oneRequired']
    }
}

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        dataRequestIsLoading: existsGet(state, 'dataRequestIsLoading', false),
        googleLibsAreLoading: existsGet(state, 'googleLibsAreLoading', true),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            electricityQuoteRequired: existsGet(state, 'connections.electricityQuoteRequired', false),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
            projectIsChecked: existsGet(state, 'connections.projectIsChecked'),
            ballparkEstimateIsChecked: existsGet(state, 'connections.ballparkEstimateIsChecked')
        },
        services: {
            generalEnquiryIsChecked: existsGet(state, 'services.generalEnquiryIsChecked'),
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
            fibreIsChecked: existsGet(state, 'services.fibreIsChecked')
        },
        contacts: existsGet(state, 'contacts', {}),
        roles: existsGet(state, 'roles', null),
        savedState: existsGet(state, 'savedState', null),
        nameAndAddressHeaderTitleOverrideText: existsGet(state, 'nameAndAddressHeaderTitleOverrideText', null)
    }
}

class WelcomeAndServices extends Component {
    constructor(props) {
        super(props)

        this.state = {
            oneRequiredError: false,
            hasProjectAndMore: false,
            hasBallparkEstimateAndMore: false,
            hasAlterationAndMore: false,
            hasDecommissionAndMore: false,
            hasGeneralEnquiryAndMore: false,
            hasNewSubdivisionAndMore: false,
            hasOnsiteGenerationAndMore: false,
            hideSummary: !this.props.fromSummary,
            loadingForward: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.loadingForward && (
            (prevProps.dataRequestIsLoading !== this.props.dataRequestIsLoading && !this.props.dataRequestIsLoading) ||
            (prevProps.googleLibsAreLoading !== this.props.googleLibsAreLoading && !this.props.googleLibsAreLoading)
        )
        )
        {
            this.validateAndNavigate()
            return
        }

        if (serviceChanged(prevProps, this.props) && !this.state.hideSummary)
        {
            this.setState({hideSummary: true})
        }

        if (this.state.hasAlterationAndMore && this.props.connections.alterationIsChecked && onlyHasSingleOfferingChecked({offering: 'alteration', services: this.props.services, connections: this.props.connections}))
        {
            this.setState({hasAlterationAndMore: false})
        }

        if (this.state.hasDecommissionAndMore && this.props.services.permanentDisconnectionIsChecked && onlyHasSingleOfferingChecked({offering: 'permanentDisconnection', services: this.props.services, connections: this.props.connections}))
        {
            this.setState({hasDecommissionAndMore: false})
        }

        if (this.state.hasBallparkEstimateAndMore && this.props.connections.ballparkEstimateIsChecked && onlyHasSingleOfferingChecked({offering: 'ballparkEstimate', services: this.props.services, connections: this.props.connections}))
        {
            this.setState({hasBallparkEstimateAndMore: false})
        }

        if (this.state.hasGeneralEnquiryAndMore && this.props.services.generalEnquiryIsChecked && onlyHasSingleOfferingChecked({offering: 'generalEnquiry', services: this.props.services, connections: this.props.connections}))
        {
            this.setState({hasGeneralEnquiryAndMore: false})
        }

        if (this.state.hasProjectAndMore && this.props.connections.projectIsChecked && onlyHasSingleOfferingChecked({offering: 'project', services: this.props.services, connections: this.props.connections}))
        {
            this.setState({hasProjectAndMore: false})
        }

        if (this.state.hasNewSubdivisionAndMore && this.props.connections.newSubdivisionIsChecked && onlyHasSingleOfferingChecked({offering: 'newSubdivision', services: this.props.services, connections: this.props.connections}))
        {
            this.setState({hasNewSubdivisionAndMore: false})
        }

        if (this.state.hasOnsiteGenerationAndMore && this.props.connections.onsiteGenerationIsChecked && onlyHasSingleOfferingChecked({offering: 'onsiteGeneration', services: this.props.services, connections: this.props.connections}))
        {
            this.setState({hasOnsiteGenerationAndMore: false})
        }
    }

    componentDidMount = () => {
        this.props.removeProgress({'path': '/'})
    }

    setParentState = (obj) => {
        if (this.state.loadingForward)
        {
            for (let key in obj) {
                if (obj.hasOwnProperty(key) && key.includes("connections_") || key.includes("services_")) {
                    this.setState({loadingForward: false})
                }
            }
        }

        this.setState(obj)
    }

    generateCheckboxFields = (services, type) => {
        return Object.keys(services).map((key, index) => {
            const label = services[key]['label']
            const leadNotice = services[key]['leadNotice']
            const isChecked = this.props[type][key+'IsChecked']
            const errorKeyName = type+'_'+key+'DateRequestedErrors'
            const dateRequestedErrors = this.state[errorKeyName]
            const serviceAlerts = services[key]['alerts'] ? services[key]['alerts'].map((alert) => {
                alert['type'] = 'info'
                return alert
            }) : []
            const helperContent = typeof services[key]['helperContent'] !== 'undefined' ? services[key]['helperContent'] : null
            const paymentTableSettings = typeof services[key]['paymentTableSettings'] !== 'undefined' ? services[key]['paymentTableSettings'] : null

            let electricityAlerts = []
            let needAQuoteAlerts = []

            if (isChecked && ['electricity'].includes(key))
            {
                electricityAlerts = [
                    {
                        keyName: key,
                        type: 'info',
                        checkboxFirst:<Checkbox
                            label="Yes, I need a builders temporary supply"
                            keyString={'connections.btsIsChecked'}
                            updateStoreValue={this.props.updateStoreValue}
                            isChecked={this.props.connections.btsIsChecked}
                            setParentState={this.setParentState}
                        />,
                        text:<>
                            <div>
                                <span>Find out more about Builder’s Temporary Supply on the <Link text="Northpower website" link={links.bts}/>.</span>
                            </div>
                        </>
                    }
                ]

                needAQuoteAlerts = [
                    {
                        keyName: key,
                        type: 'info',
                        text:<>
                            <div>
                                <span><b>Do you need a Ball Park Estimate for work required?</b></span>
                                <p className='mt-1'>If power is not available at the boundary of the parcel of land, work will be required to
                                    provide power to the lot before any new connection can be livened onto the Network.</p>
                                <span><b>Not sure if you have power available at the boundary of your property?</b></span>
                                <p className='mt-1 mb-0'>Give us a call on <a href="tel:0800667847">0800 667 847</a>.</p>
                            </div>
                        </>,
                        checkbox:<Checkbox
                            label="Yes, I need an estimate"
                            keyString={type+'.'+key+"QuoteRequired"}
                            updateStoreValue={this.props.updateStoreValue}
                            isChecked={this.props[type][key+'QuoteRequired']}
                            setParentState={this.setParentState}
                        />
                    }
                ]
            }

            let alerts = [...serviceAlerts, ...electricityAlerts, ...needAQuoteAlerts]

            return <div className='welcome-checkbox-wrapper' key={type+'_'+index}>
                <WelcomeCheckbox
                    keyString={type+'.'+key}
                    parentErrorKey={errorKeyName}
                    label={label}
                    notice={leadNotice}
                    isChecked={isChecked}
                    validationErrorsFromParent={dateRequestedErrors}
                    updateStoreValue={this.props.updateStoreValue}
                    setParentState={this.setParentState}
                    helperContent={helperContent}
                    nameAndAddressHeaderTitleOverrideText={this.props.nameAndAddressHeaderTitleOverrideText}
                />

                {isChecked && alerts.length > 0 ?
                    <Alert
                        topTableSettings={paymentTableSettings}
                        multiple={alerts}
                        type='info'
                    />
                : null}
            </div>
        })
    }

    validate = () => {
        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules
        })
    }

    validateAndGetNextLocation = () => {

        if (this.props.connections.alterationIsChecked && onlyHasSingleOfferingChecked({offering: 'alteration', services: this.props.services, connections: this.props.connections}))
        {
            this.props.addProgress({'path': '/'})
            return '/name-and-address'
        }
        else if (this.props.connections.alterationIsChecked)
        {
            this.setState({hasAlterationAndMore: true})
            return false
        }

        if (this.props.services.permanentDisconnectionIsChecked && onlyHasSingleOfferingChecked({offering: 'permanentDisconnection', services: this.props.services, connections: this.props.connections}))
        {
            this.props.addProgress({'path': '/'})
            return '/name-and-address'
        }
        else if (this.props.services.permanentDisconnectionIsChecked)
        {
            this.setState({hasDecommissionAndMore: true})
            return false
        }

        if (this.props.connections.ballparkEstimateIsChecked && onlyHasSingleOfferingChecked({offering: 'ballparkEstimate', services: this.props.services, connections: this.props.connections}))
        {
            this.props.addProgress({'path': '/'})
            return '/name-and-address'
        }
        else if (this.props.connections.ballparkEstimateIsChecked)
        {
            this.setState({hasBallparkEstimateAndMore: true})
            return false
        }

        if (this.props.connections.projectIsChecked && onlyHasSingleOfferingChecked({offering: 'project', services: this.props.services, connections: this.props.connections}))
        {
            this.props.addProgress({'path': '/'})
            return '/name-and-address'
        }
        else if (this.props.connections.projectIsChecked)
        {
            this.setState({hasProjectAndMore: true})
            return false
        }

        if (this.props.services.generalEnquiryIsChecked && onlyHasSingleOfferingChecked({offering: 'generalEnquiry', services: this.props.services, connections: this.props.connections}))
        {
            this.props.addProgress({'path': '/'})
            return '/name-and-address'
        }
        else if (this.props.services.generalEnquiryIsChecked)
        {
            this.setState({hasGeneralEnquiryAndMore: true})
            return false
        }

        if (this.props.connections.newSubdivisionIsChecked && onlyHasSingleOfferingChecked({offering: 'newSubdivision', services: this.props.services, connections: this.props.connections}))
        {
            this.props.addProgress({'path': '/'})
            return '/name-and-address'
        }
        else if (this.props.connections.newSubdivisionIsChecked)
        {
            this.setState({hasNewSubdivisionAndMore: true})
            return false
        }

        if (this.props.connections.onsiteGenerationIsChecked && onlyHasSingleOfferingChecked({offering: 'onsiteGeneration', services: this.props.services, connections: this.props.connections}))
        {
            this.props.addProgress({'path': '/'})
            return '/name-and-address'
        }
        else if (this.props.connections.onsiteGenerationIsChecked)
        {
            this.setState({hasOnsiteGenerationAndMore: true})
            return false
        }

        if (!this.validate())
        {
            this.props.removeProgress({'path': '/'})
            return false
        }

        this.props.addProgress({'path': '/'})

        return hasNonConnectionService(this.props.services) ? '/preferred-dates' : '/name-and-address'
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            if (this.props.dataRequestIsLoading || this.props.googleLibsAreLoading)
            {
                if (!this.state.loadingForward)
                    this.setState({loadingForward: true})

                return false
            }

            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'summary' && this.validate())
        {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    getErrors = () => {
        if (this.state.hasBallparkEstimateAndMore)
        {
            return <Alert text={'Sorry, you cannot select multiple options when you\'ve selected "Ball Park Estimate".'} type="danger" />
        }
        else if (this.state.hasProjectAndMore)
        {
            return <Alert text={'Sorry, you cannot select multiple options when you\'ve selected "Projects that affect Northpower Network Infrastructure".'} type="danger" />
        }
        else if (this.state.hasNewSubdivisionAndMore)
        {
            return <Alert text={'Sorry, you cannot select multiple options when you\'ve selected "New Subdivision".'} type="danger" />
        }
        else if (this.state.hasOnsiteGenerationAndMore)
        {
            return <Alert
                text={'Sorry, you cannot select multiple options when you\'ve selected "Alternative Energy (Solar/Wind/Battery) Connection".'}
                type="danger"/>
        }
        else if (this.state.hasGeneralEnquiryAndMore)
        {
            return <Alert text={'Sorry, you cannot select multiple options when you\'ve selected "General Enquiry".'} type="danger" />
        }
        else if (this.state.hasAlterationAndMore)
        {
            return <Alert text={'Sorry, you cannot select multiple options when you\'ve selected "Alteration to Existing Connection".'} type="danger" />
        }
        else if (this.state.hasDecommissionAndMore)
        {
            return <Alert text={'Sorry, you cannot select multiple options when you\'ve selected "Permanent Disconnection / Decommissions".'} type="danger" />
        }

        if (this.state.oneRequiredError)
        {
            if (
                // this.props.services.permanentDisconnectionIsChecked ||
                this.props.services.highLoadIsChecked ||
                this.props.services.fibreIsChecked
            )
            {
                return <Alert text='The service/s you have chosen need to be processed externally, please read the instructions underneath your selection.' type="danger" />
            }
            else
            {
                return <Alert text='Please select the service you’d like to get started with.' type="danger" />
            }
        }

        return null
    }

    render() {
        const serviceCheckboxesData = getServiceCheckboxesData(this.props)
        const connections = serviceCheckboxesData.connections
        const services = serviceCheckboxesData.services
        const errors = this.getErrors()

        return (
            <div id="welcome-and-services" className="section-margin-bottom">
                { !this.props.picklistIsLoading ?
                <>
                    <Header
                        title='Hi, how can we help today?'
                        description={<div className='welcome-and-services__header-description'>
                            <span>Choose from our list of services to get started. You can select more than one if you have a few things to sort out.</span>
                            <span className='mt-2'>If you have any questions please call customer care on <a href="tel:0800667847">0800 667 847</a>.</span>
                        </div>}
                    />

                    <Navigation isTop={true} setParentState={this.setParentState} hideSummary={this.state.hideSummary} hideBack={true} handleNavigation={this.handleNavigation} loadingForward={this.state.loadingForward} />

                    <div ref={this.props.sectionWrapperRef} className="section">
                        <div className="container">
                            {errors !== null ?
                                <div className="validation-top-wrapper">
                                    {errors}
                                </div>
                            : null}
                            <Alert text="Choose from our list of services to get started. You can select more than one if you have a few things to sort out." type="description" />
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="heading-wrapper">
                                        <div className='h2-wrapper'>
                                            <h2>Connections</h2>
                                        </div>
                                    </div>
                                    {this.generateCheckboxFields(connections, 'connections')}
                                </div>
                                <div className="col-lg-6">
                                    <div className="heading-wrapper">
                                        <div className='h2-wrapper'>
                                            <h2>Other Services</h2>
                                        </div>
                                    </div>
                                    {this.generateCheckboxFields(services, 'services')}
                                </div>
                            </div>

                            <Alert text={<span>Don’t see the service you want? Visit the <Link text="Northpower website" link={links.contact} /> to contact us about it.</span>} type="info" />
                            {this.props.showBottomNavigation && errors !== null ?
                                <div className="validation-bottom-wrapper">
                                    {errors}
                                </div>
                            : null}
                        </div>
                    </div>

                    {this.props.showBottomNavigation ?
                        <Navigation hideSummary={this.state.hideSummary} hideBack={true} handleNavigation={this.handleNavigation} loadingForward={this.state.loadingForward} />
                    : null}
                </>
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <WelcomeAndServices ref={ref} {...props} />
    })
)
