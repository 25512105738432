import React from 'react'

export default <div>
    <p><b>Confirmation of permanent disconnection:</b></p>
    <ol>
        <li>
            I confirm the request for permanent disconnection and agree that I will engage with the contractor I have chosen to safely cut away my private service lines to the network assets.
        </li>
        <li>I confirm I am responsible for any remaining service lines or cables on the property that are not owned by Northpower (private service line).
            I understand that, for my  own safety and the safety of others, I will wait for written clearance that the electricity services have been safely decommissioned (removed from Northpower’s network)  before starting to demolish or remove a building or before working in the area of the live cable.</li>
    </ol>
    <br/>
    <p><b>Acknowledgement:</b></p>
    <ol>
        <li>I understand there are costs associated with performing a permanent disconnection and agree to pay my chosen contractor to cut away the lines to physically disconnect from Northpower’s network.</li>
        <li>I also understand that there will be costs to remove the meter – and these will be advised by and charged by my retailer.</li>
        <li>I acknowledge that should there be additional costs for removal of network assets I will need to enter an agreement with Northpower for their removal and associated costs.</li>
        <li>By agreeing to these terms and conditions, the undersigned confirms that they have obtained explicit permission from the landowner to authorize the works to be completed on the specified property.</li>
    </ol>
</div>
