export default (options, key ) => {
    const hasKey = typeof key !== 'undefined'

    return options.map((option) => {
        const value = hasKey ? option[key] : option

        return {
            value: value,
            label: value
        }
    }).sort((a, b) => a.value.toString().localeCompare(b.value.toString()))
}
