import React, {Component} from 'react'
import {connect} from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import validator from "../../../services/validator/validator"
import Alert from "../../common/alert"
import Navigation from "../../common/navigation"
import Header from "../../common/header"
import CheckboxRadio from "../../common/checkbox-radio"
import hasCoordinates from "../../../helpers/has-coordinates"
import hasError from "../../../helpers/has-error"

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        connections: existsGet(state, 'connections', null),
        propertyType: existsGet(state, 'propertyType', null),
        firstName: existsGet(state, 'contacts.0.firstName', null),
        serviceAddress: existsGet(state, 'serviceAddress', null),
        googleServiceAddress: existsGet(state, 'google.serviceAddress', null)
    }
}

const validationRules = {
    'propertyType': ['required']
}

const validationMessages = {
    'propertyTypeErrors': {
        'required': 'Please select your connection type.'
    }
}

const content = require('../../../json/content/content.json')

class PropertyType extends Component {
    constructor(props) {
        super(props)

        this.validationErrorFields = {
            propertyTypeErrors: []
        }

        this.state = {
            ...this.validationErrorFields,
            hideSummary: !this.props.fromSummary
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.propertyType !== this.props.propertyType && this.props.propertyType === 'Caravan')
        {
            this.setState({hideSummary: true})
        }
    }

    isBallparkEstimate = () => {
        return this.props.connections.ballparkEstimateIsChecked
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    validate = () => {
        if (this.isBallparkEstimate())
            return true // skip validator

        return validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules,
            validationErrorFields: this.validationErrorFields
        })
    }

    validateAndGetNextLocation = () => {
        if (this.validate()){
            this.props.addProgress({'path': '/property-type'})
            return '/prerequisites'
        }

        this.props.removeProgress({'path': '/property-type'})
        return false
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/property-type'})

            if ((this.props.serviceAddress !== null && this.props.serviceAddress.addressAddedManually) || !hasCoordinates(this.props.serviceAddress))
            {
                this.props.updateLocation('/name-and-address', null, 'back')

                return true
            }

            this.props.updateLocation('/confirm-location', null, 'back')

            return true
        }

        if (direction === 'summary' && this.validate())
        {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    getCheckboxOptions = () => {
        return [
            {'label': 'House', 'value': 'Residential Dwelling'},
            {'label': 'Caravan/Cabin', 'value': 'Caravan'},
            {'label': 'Commercial', 'value': 'Commercial'},
            {'label': 'Farm Utilities', 'value': 'Farm Utilities'},
            {'label': 'Pump', 'value': 'Pump'},
            {'label': 'Shed', 'value': 'Shed'},
            {'label': 'Shop', 'value': 'Shop'},
            {'label': 'Workshop', 'value': 'Workshop'},
        ]
    }

    render() {
        const showValidationError = hasError(this.state, this.validationErrorFields)

        return (
            <div id="property-type" className="section-margin-bottom">
                <Header
                    title={<>
                        Thanks {this.props.firstName}, please let us know the type of property at the service address
                    </>}
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={this.state.hideSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col checkbox-only">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                        <div className="wrapper">
                            <CheckboxRadio
                                keyString="propertyType"
                                parentErrorKey="propertyTypeErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.propertyType}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.propertyTypeErrors}
                                validationErrorsFromParent={this.state.propertyTypeErrors}
                                checkboxes={this.getCheckboxOptions()}
                            />
                        </div>
                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={this.state.hideSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <PropertyType ref={ref} {...props} />
    })
)
