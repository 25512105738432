import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import Navigation from "../../common/navigation"
import Alert from "../../common/alert"
import Textarea from "../../common/textarea/textarea"
import Header from "../../common/header"
import getCheckedFields from "../../../helpers/get-checked-fields"
import Upload from "../../common/upload"
import {getPreviousSectionSlug} from "../../../services/router/non-connection-service"
import Button from "../../common/button"

const mapStateToProps = (state) => {

    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: {
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
        },
        propertyType: existsGet(state, 'propertyType', null),
        otherInformation: {
            notes: existsGet(state, 'otherInformation.notes', ''),
            locationInformation: existsGet(state, 'otherInformation.locationInformation', ''),
            uploads: existsGet(state, 'otherInformation.uploads', [])
        },
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
            projectIsChecked: existsGet(state, 'connections.projectIsChecked'),
            ballparkEstimateIsChecked: existsGet(state, 'connections.ballparkEstimateIsChecked')
        },
        previousUploadFileNames: existsGet(state, 'previousUploadFileNames', []),
        previousUploadsReset: existsGet(state, 'previousUploadsReset', false),
        uploadsModifiedAfterSubmitPage: existsGet(state, 'uploadsModifiedAfterSubmitPage', null)
    }
}


class OtherInformation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            uploadRequiredError: false
        }
    }

    isDecommission = () => {
        return typeof this.props.services.permanentDisconnectionIsChecked !== 'undefined' && this.props.services.permanentDisconnectionIsChecked
    }

    isProject = () => {
        return this.props.connections.projectIsChecked
    }

    isSubdivision = () => {
        return this.props.connections.newSubdivisionIsChecked
    }

    isBallparkEstimate = () => {
        return this.props.connections.ballparkEstimateIsChecked
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    newUpload = (fileName, fileSize, binaryStr) => {
        if (this.state.uploadRequiredError)
        {
            this.setState({uploadRequiredError: false})
        }

        this.updateUploadsModifiedAfterSubmitPage()

        this.props.updateStoreValue('otherInformation.uploads', [...this.props.otherInformation.uploads, {name: fileName, data: binaryStr, size: fileSize}])
    }

    removeUploadedFile = (id) => {
        this.updateUploadsModifiedAfterSubmitPage()
        this.props.updateStoreValue('otherInformation.uploads', this.props.otherInformation.uploads.filter((file, i) => i !== id))
    }

    updateUploadsModifiedAfterSubmitPage = () => {
        // this will be set to false in the submit sendConnectionPayload, but defaults to null
        if (this.props.uploadsModifiedAfterSubmitPage === false)
            this.props.updateStoreValue('uploadsModifiedAfterSubmitPage', true)
    }

    resetUploads = () => {
        this.props.updateStore({
            previousUploadsReset: true,
            previousUploadFileNames: []
        })
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'summary' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/other-information'})

            if (this.isProject() || this.isSubdivision() || this.isBallparkEstimate())
                return this.props.updateLocation('/manual-capacity', null, 'back')

            if (this.isDecommission())
                return this.props.updateLocation('/approved-contractor', null, 'back')

            const previousSectionSlug = getPreviousSectionSlug(this.props.services, this.props.connections, this.props.propertyType, this.props.historyLocation)

            const connectionsChecked = getCheckedFields(this.props.connections)
            if (connectionsChecked.length === 1 && connectionsChecked.includes('newSubdivision'))
            {
                if (previousSectionSlug !== null)
                {
                    return this.props.updateLocation(previousSectionSlug)
                }

                return this.props.updateLocation('/roles-hub', null, 'back')
            }

            if (previousSectionSlug !== null)
            {
                return this.props.updateLocation(previousSectionSlug, null, 'back')
            }

            if (this.props.connections.onsiteGenerationIsChecked)
            {
                return this.props.updateLocation('/distributed-generation-capacity', null, 'back')
            }

            const checkedConnections = getCheckedFields(this.props.connections)
            if (checkedConnections.length === 1 && checkedConnections[0] === 'bts')
            {
                return this.props.updateLocation('/approved-contractor', null, 'back')
            }



            return this.props.updateLocation('/manual-capacity', null, 'back')
        }
    }

    validateAndGetNextLocation = () =>
    {
        if (this.props.connections.newSubdivisionIsChecked && this.props.otherInformation.uploads.length === 0 && this.props.previousUploadFileNames.length === 0)
        {
            this.setState({uploadRequiredError: true})
            return false
        }

        this.props.addProgress({'path': '/other-information'})
        return '/summary'
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)
        }
    }

    render() {
        return (
            <div id="other-information" className="section-margin-bottom">
                <Header
                    title="Is there anything else we need to know?"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {this.state.uploadRequiredError ?
                            <div className="validation-top-wrapper">
                                <Alert text='Please upload your plan.' type="danger" />
                            </div>
                        : null}

                        {!this.isProject() ?
                            <Alert type="description" text={
                                <div>
                                    Are there any hazards on your property? Tricky access? Please add any notes about your job that we should be aware of.
                                </div>
                            } />
                        : null}

                        <div className="wrapper">
                            <Textarea
                                label="Do you need us to know any additional information about your request?"
                                keyString="otherInformation.notes"
                                value={this.props.otherInformation.notes}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            {!this.isProject() ?
                                <Textarea
                                    label="About your location - are there any hazards on your property? Tricky access? Large dog?"
                                    keyString="otherInformation.locationInformation"
                                    value={this.props.otherInformation.locationInformation}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />
                            : null}

                            {this.props.previousUploadFileNames.length > 0 ?
                                <>
                                    <Alert type='description'>
                                        <div>
                                            <span>The below files have been uploaded previously:</span>
                                            <ul>
                                                {this.props.previousUploadFileNames.map((fileName, i) => (<li key={'file-name-'+i}>{fileName}</li>))}
                                            </ul>
                                            <span>If you want to modify or add uploads, you need to reset and re-upload all files. </span>
                                        </div>
                                    </Alert>
                                    <Button text='Reset and re-upload' action={this.resetUploads} />
                                </>
                            : null}

                            {this.props.previousUploadFileNames.length === 0 ?
                                <Upload
                                    newUpload={this.newUpload}
                                    currentFiles={this.props.otherInformation.uploads}
                                    removeUploadedFile={this.removeUploadedFile}
                                    uploadRequiredError={this.state.uploadRequiredError}
                                    isProject={this.isProject()}
                                />
                            : null}
                        </div>

                        {this.props.showBottomNavigation && this.state.uploadRequiredError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text='Please upload your plan.' type="danger" />
                            </div>
                        : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={!this.props.fromSummary} handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <OtherInformation ref={ref} {...props} />
    })
)
