import React, {Component} from 'react'
import {connect} from "react-redux"
import existsGet from "../../../helpers/exists-get"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import validator from "../../../services/validator/validator"
import Input from "../../common/input"
import Alert from "../../common/alert"
import GoogleAddress from "./google-address"
import Navigation from "../../common/navigation"
import Header from "../../common/header"
import MandatoryText from "../../common/mandatory-text"
import hasCoordinates from "../../../helpers/has-coordinates"
import getCheckedFields from "../../../helpers/get-checked-fields"
import {getNextNonConnectionService} from "../../../services/router/non-connection-service"
import shouldShowPropertyType from "../../../helpers/should-show-property-type"
import Dropdown from "../../common/dropdown"
import Checkbox from "../../common/checkbox"
import Textarea from "../../common/textarea/textarea"
import MandatoryIndicator from "../../common/mandatory-indicator"
import Link from "../../common/link"
import TooltipIcon from "../../../assets/svgs/tooltip.svg"
import Modal from "../../common/modal"
import hasNonConnectionService from "../../../helpers/has-non-connection-service"
import hasConnectionAndNonConnectionServices from "../../../helpers/has-connection-and-non-connection-services"
import {produce} from "immer"
import IcpNumber from "../../common/icp-number"
import Tooltip from "../../common/tooltip"
import getIcpNumberHelperText from "../../../helpers/get-icp-number-helper-text"
import shouldPreventNextIcpTriggerNavigation from "../../../helpers/should-prevent-next-icp-trigger-navigation"
import hasError from "../../../helpers/has-error"

const defaultServiceAddressObject = require('../../../json/defaults/default-service-address-object.json')

const mapStateToProps = (state) => {

    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        yourDetails: existsGet(state, 'contacts.0', null),
        google: {
            serviceAddress: existsGet(state, 'google.serviceAddress', null)
        },
        serviceAddress: existsGet(state, 'serviceAddress', null),
        readyPropertyIsChecked: existsGet(state, 'fibre.readyPropertyIsChecked', false),
        fibreStatus: existsGet(state, 'fibre.status', null),
        showServiceAddressFields: existsGet(state, 'showServiceAddressFields', false),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
            projectIsChecked: existsGet(state, 'connections.projectIsChecked'),
            ballparkEstimateIsChecked: existsGet(state, 'connections.ballparkEstimateIsChecked')
        },
        services: {
            generalEnquiryIsChecked: existsGet(state, 'services.generalEnquiryIsChecked'),
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
        },
        project: {
            siteLocationDetails: existsGet(state, 'project.siteLocationDetails', ''),
        },
        roles: existsGet(state, 'roles', null),
        contacts: existsGet(state, 'contacts', {}),
        fromServiceDeeplink: existsGet(state, 'fromServiceDeeplink', false),
        hasConnectionsAndServices: hasConnectionAndNonConnectionServices({
            services: state.services,
            connections: state.connections
        }),
        nameAndAddressHeaderTitleOverrideText: existsGet(state, 'nameAndAddressHeaderTitleOverrideText', null),
        generalEnquiry: {
            icpNumber: existsGet(state, 'generalEnquiry.icpNumber', '')
        },
        // start icp-number requirements
        icpNumbersApproved: existsGet(state, 'icpNumbersApproved', []),
        icpNumbersDenied: existsGet(state, 'icpNumbersDenied', []),
        icpNumberPendingApprovalLatest: existsGet(state, 'icpNumberPendingApprovalLatest', [])
        // end icp-number requirements
    }
}

const validationMessages = {
    firstName: {
        required: 'Please enter your first name.',
        min: 'Your first name must be a minimum of {int} characters long.',
        max: 'Your first name must be a maximum of {int} characters long.',
        lettersSpacesHyphens: 'Your name can only contain letters, spaces and hyphens.'
    },
    lastName: {
        required: 'Please enter your surname.',
        min: 'Your last name must be a minimum of {int} characters long.',
        max: 'Your last name must be a maximum of {int} characters long.',
        lettersSpacesHyphens: 'Your last name can only contain letters, spaces and hyphens.'
    },
    phoneNumber: {
        required: 'Please enter your phone number.',
        phoneNumber: 'Please enter a valid phone number.'
    },
    emailAddress: {
        emailAddress: 'Please enter a valid email address.',
        required: 'Please enter your email address.'
    },
    companyName: {
        min: 'Your company name must be a minimum of {int} characters long.',
        max: 'Your company name must be a maximum of {int} characters long.'
    },
    preferredMethodOfContact: {
        required: 'Please select your preferred method of contact.'
    },
    google_serviceAddress: {
        requiredWithout: 'Please enter and select the service address.',
        required: 'Please enter and select the service address.'
    },
    serviceAddress_unit: {
        // maxNumericChars: 'Your unit number must contain a maximum of {int} numeric characters.',
        // maxAlphabeticalChars: 'Your unit number must contain a maximum of {int} alphabetical characters.',
        unitNumber: 'Your unit number must contain a maximum of 3 numeric characters and 1 alphabetical character.'
    },
    serviceAddress_number: {
        maxNumericChars: 'Your address street number must contain a maximum of {int} numeric characters.',
        maxAlphabeticalChars: 'Your address street number must contain a maximum of {int} alphabetical characters.',
        allowOnlyHyphensForwardSlashAlphaNumericSpaces: 'Your address street number must only contain spaces, hyphens, forward slashes and alphanumeric characters.'
    },
    serviceAddress_street: {
        min: 'Your address street must be a minimum of {int} characters long.',
        max: 'Your address street must be a maximum of {int} characters long.',
        required: 'Please enter your street name.',
        nonNumeric: 'Your address street cannot contain numbers, if you added your RD number please remove it.',
        streetType: 'We require a street type in this field e.g. Road, Crescent, Close.'
    },
    serviceAddress_suburb: {
        min: 'Your address suburb must be a minimum of {int} characters long.',
        max: 'Your address suburb must be a maximum of {int} characters long.',
        nonNumeric: 'Your address suburb cannot contain numbers, if you added your RD number please remove it.'
    },
    serviceAddress_city: {
        min: 'Your address city must be a minimum of {int} characters long.',
        max: 'Your address city must be a maximum of {int} characters long.',
        nonNumeric: 'Your address city cannot contain numbers, if you added your RD number please remove it.',
        required: 'Please enter your city.'
    },
    serviceAddress_dairyNumber: {
        numeric: 'Please enter a valid dairy number.'
    },
    serviceAddress_dpNumber: {
        numeric: 'Please enter a valid DP number.',
        required: 'Please enter a DP number.',
        min: 'Your DP number must be a minimum of {int} characters long.',
        max: 'Your DP number must be a maximum of {int} characters long.',
        illegalStrings: 'The DP number you entered is invalid.'
    },
    serviceAddress_lotNumber: {
        numeric: 'Please enter a valid lot number.',
        required: 'Please enter a lot number.',
        min: 'Your lot number must be a minimum of {int} characters long.',
        max: 'Your lot number must be a maximum of {int} characters long.',
        illegalStrings: 'The lot number you entered is invalid.'
    },
    serviceAddress_legalDescription: {
        required: 'Please enter a legal description.'
    },
    project_siteLocationDetails: {
        required: 'Please enter your site address details.',
        max: 'Your address city must be a maximum of {int} characters long.'
    }
}

const content = require('../../../json/content/content.json')

class NameAndAddress extends Component {
    constructor(props) {
        super(props)

        this.validationErrorFields = {
            yourDetails_firstNameErrors: [],
            yourDetails_lastNameErrors: [],
            yourDetails_phoneNumberErrors: [],
            yourDetails_emailAddressErrors: [],
            yourDetails_companyNameErrors: [],
            yourDetails_preferredMethodOfContactErrors: [],
            serviceAddress_unitErrors: [],
            serviceAddress_numberErrors: [],
            serviceAddress_streetErrors: [],
            serviceAddress_suburbErrors: [],
            serviceAddress_cityErrors: [],
            serviceAddress_dairyNumberErrors: [],
            serviceAddress_dpNumberErrors: [],
            serviceAddress_lotNumberErrors: [],
            serviceAddress_legalDescriptionErrors: [],
            serviceAddress_cantFindLotAndDpNumberErrors: [],
            project_siteLocationDetailsErrors: [],
            google_serviceAddressErrors: [],
            generalEnquiry_icpNumberErrors: [],
        }

        this.state = {
            ...this.validationErrorFields,
            variableAddressIdentifierHasError: false,
            hideSummary: !this.showUpdateButton(),
            hideBack: this.props.fromSummary,
            hasOnlyDpNumberOrLotNumber: null,
            showLegalDescriptionModal: false,
            showLotDpHelperModal: false,
            // start icp-number requirements
            loadingForward: false,
            triggerIcpValidation: false,
            pendingIcpTriggerNavigation: false
            // end icp-number requirements
        }

        // start icp-number requirements
        this.preventNextIcpTriggerNavigation = false
        this.direction = 'next'
        // end icp-number requirements

        const checkedConnections = getCheckedFields(this.props.connections)

        this.onlyNonConnection = checkedConnections.length === 0

        this.defaultServiceAddressValidationRules = {
            // unit: [{'maxNumericChars': 3}, {'maxAlphabeticalChars': 1}],
            unit: ['unitNumber'],
            number: [{'maxNumericChars': 6}, {'maxAlphabeticalChars': 1}, 'allowOnlyHyphensForwardSlashAlphaNumericSpaces'],
            street: [{'min': 2}, {'max': 50}, 'required', 'nonNumeric', 'streetType', 'nonNumericBypassIfHighway'],
            suburb: [{'min': 2}, {'max': 50}, 'nonNumeric'],
            city: [{'min': 2}, {'max': 50}, 'required', 'nonNumeric'],
            dairyNumber: ['numeric'],
            legalDescription: []
        }

        this.defaultGoogleServiceAddressValidationRules = [{'requiredWithout': 'serviceAddress.number,serviceAddress.street,serviceAddress.suburb,serviceAddress.city'}]

        let validationRules = {}

        if (!this.isProject() && !(this.isGeneralEnquiry() && props.serviceAddress.enquiryNotRelatedToSiteLocation)) {
            validationRules = Object.assign({}, validationRules, {
                serviceAddress: this.defaultServiceAddressValidationRules
            })

            // Only add Google validation if address not manually entered
            if (!existsGet(props, 'serviceAddress.addressManuallyAdded', false)) {
                validationRules.google = {
                    serviceAddress: this.defaultGoogleServiceAddressValidationRules
                }
            }
        }

        if (this.onlyNonConnection && !this.isDecommission()) {

            validationRules = Object.assign({}, validationRules, {
                yourDetails: {
                    firstName: ['required', {'min': 2}, {'max': 25}, 'lettersSpacesHyphens'],
                    lastName: ['required', {'min': 2}, {'max': 25}, 'lettersSpacesHyphens']
                }
            })

            let emailAddressRules = ['emailAddress', 'required']

            if (!this.emailAddressIsRequired())
                emailAddressRules = ['emailAddress']

            let updatedYourDetails = {
                ...validationRules.yourDetails,
                'phoneNumber': ['phoneNumber', 'required'],
                'emailAddress': emailAddressRules,
                'companyName': [{'min': 2}, {'max': 50}]
            }

            if (!this.isGeneralEnquiry()) {
                updatedYourDetails = Object.assign({}, updatedYourDetails, {
                    'preferredMethodOfContact': ['required'],
                })
            }

            validationRules = Object.assign({}, validationRules, {
                yourDetails: updatedYourDetails
            })
        }

        if (this.isProject()) {
            validationRules = Object.assign({}, validationRules, {
                project: {
                    siteLocationDetails: [{'max': 500}, 'required']
                }
            })
        }

        if (!this.isProject()) {
            validationRules = this.getDpLotLegalRules(props, validationRules)
        }

        this.state.validationRules = validationRules
    }

    componentDidUpdate = (prevProps) => {
        const historyAction = this.props.historyAction

        if (prevProps.historyAction !== historyAction && historyAction === 'next') {
            this.validateAndNavigate()
        }

        if (prevProps.google.serviceAddress !== this.props.google.serviceAddress) {
            if (!this.props.showServiceAddressFields) {
                this.props.updateStoreValue('showServiceAddressFields', true)
            }

            if (this.props.google.serviceAddress === null && !this.props.serviceAddress.enquiryNotRelatedToSiteLocation) {
                this.props.updateStoreValue('serviceAddress', defaultServiceAddressObject)
            }
        }

        if (
            (prevProps.showServiceAddressFields !== this.props.showServiceAddressFields && this.props.showServiceAddressFields) || // service address has previously been populated
            (prevProps.serviceAddress !== this.props.serviceAddress && this.hasAddressData()) // service address is only just populated on this render
        ) {
            // remove google validation rules after address has been selected, or if address is set to be manually entered
            if (Object.keys(this.state.validationRules).includes('google')) {
                this.setState((state) => {
                    const validationRules = state.validationRules

                    return {
                        validationRules: Object.keys(validationRules).filter(key => key !== 'google').reduce((obj, key) => {
                            obj[key] = validationRules[key]

                            return obj
                        }, {})
                    }
                })
            }

            // remove any google related validation errors
            if (this.state.google_serviceAddressErrors.length > 0) {
                this.setState({google_serviceAddressErrors: []})
            }
        }

        // removes address details required error when address entered
        if (this.state.variableAddressIdentifierHasError && !this.variableAddressIdentifierShouldHaveError()) {
            this.setState({variableAddressIdentifierHasError: false})
        }

        if (prevProps.serviceAddress.cantFindLotAndDpNumber !== this.props.serviceAddress.cantFindLotAndDpNumber) {
            const cantFindLotAndDpNumber = this.props.serviceAddress.cantFindLotAndDpNumber

            const dpLotErrorObject = {
                serviceAddress_dpNumberErrors: [],
                serviceAddress_lotNumberErrors: []
            }

            const legalDescriptionErrorObject = {
                serviceAddress_legalDescriptionErrors: []
            }

            const errorObjectToUpdate = cantFindLotAndDpNumber ? dpLotErrorObject : legalDescriptionErrorObject
            this.setState(errorObjectToUpdate) // this needs to be standalone, before the next setState

            this.setState((state, props) => {
                const validationRules = this.getDpLotLegalRules(props, state.validationRules)

                return {validationRules}
            })

            if (!this.props.serviceAddress.cantFindLotAndDpNumber) {
                this.props.updateStoreValue('serviceAddress.legalDescription', '')
            }
        }

        if (prevProps.serviceAddress.enquiryNotRelatedToSiteLocation !== this.props.serviceAddress.enquiryNotRelatedToSiteLocation) {
            if (this.props.serviceAddress.enquiryNotRelatedToSiteLocation) {
                const serviceAddressObject = Object.assign({}, defaultServiceAddressObject, {enquiryNotRelatedToSiteLocation: true})
                this.props.updateStore({
                    'serviceAddress': serviceAddressObject,
                    'google': {serviceAddress: null}
                })

                // remove address related rules
                this.setState((prevState) => {
                    return {
                        validationRules: produce(prevState['validationRules'], draft => {
                            delete draft["serviceAddress"]
                            delete draft["google"]
                        }),
                        serviceAddress_unitErrors: [],
                        serviceAddress_numberErrors: [],
                        serviceAddress_streetErrors: [],
                        serviceAddress_suburbErrors: [],
                        serviceAddress_cityErrors: [],
                        serviceAddress_dairyNumberErrors: [],
                        serviceAddress_lotNumberErrors: [],
                        serviceAddress_dpNumberErrors: [],
                        serviceAddress_legalDescriptionErrors: [],
                        google_serviceAddressErrors: [],
                        variableAddressIdentifierHasError: false
                    }
                })
            } else {
                this.setState((prevState, props) => {
                    return {
                        validationRules: produce(prevState['validationRules'], draft => {
                            draft["serviceAddress"] = this.defaultServiceAddressValidationRules
                            draft["google"] = this.defaultGoogleServiceAddressValidationRules
                        })
                    }
                })
            }
        }

        // start icp-number requirements
        if (this.state.loadingForward) {
            const changeableValues = [
                'generalEnquiry.icpNumber'
            ]

            if (shouldPreventNextIcpTriggerNavigation(changeableValues, prevProps, this.props)) {
                this.preventNextIcpTriggerNavigation = true
            }
        }

        if (
            !this.preventNextIcpTriggerNavigation &&
            this.state.pendingIcpTriggerNavigation &&
            prevProps.icpNumbersApproved.length !== this.props.icpNumbersApproved.length
        ) {
            this.setState({pendingIcpTriggerNavigation: false}, () => {
                this.handleNavigation(this.direction)
            })
        }

        if (this.state.pendingIcpTriggerNavigation && this.preventNextIcpTriggerNavigation) {
            this.setState({pendingIcpTriggerNavigation: false})
            this.preventNextIcpTriggerNavigation = false
        }
        // end icp-number requirements

        const shouldHideSummary = this.showUpdateButton()
        if (!shouldHideSummary !== this.state.hideSummary) {
            this.setState({hideSummary: !shouldHideSummary})
        }
    }

    showUpdateButton = () => {
        return (
            this.props.fromSummary &&
            (
                existsGet(this.props, 'serviceAddress.addressManuallyAdded', false) === true ||
                !hasCoordinates(this.props.serviceAddress) ||
                this.isProject() ||
                this.props.serviceAddress.enquiryNotRelatedToSiteLocation
            )
        )
    }

    emailAddressIsRequired = () => {
        return false
        /*const checkedNonConnectionServices = getCheckedFields(this.props.services)
        const onlyVegetationControl = checkedNonConnectionServices.length === 1 && checkedNonConnectionServices.includes('vegetationControl')

        return this.onlyNonConnection && !onlyVegetationControl*/
    }

    getDpLotLegalRules = (props, validationRules) => {
        let rules = {}
        let baseRules = {
            dpNumber: [
                'numeric',
                {'min': 5},
                {'max': 6},
                {'illegalStrings': ['00000', '11111', '22222', '33333', '44444', '55555', '66666', '77777', '88888', '99999', '12345', '56789', '12312', '000000', '111111', '222222', '333333', '444444', '555555', '666666', '777777', '888888', '999999', '123456', '67890', '123123']}
            ],
            lotNumber: [
                'numeric',
                {'max': 3},
                {'illegalStrings': ['123', '000', '111', '222', '333', '444', '555', '666', '777', '888', '999', '456', '789']}
            ],
            legalDescription: [] // needs to be reset, otherwise rule merge will include old rule
        }

        if (this.variableAddressIdentityFieldsRequired()) {
            rules = props.serviceAddress.cantFindLotAndDpNumber ? {
                legalDescription: ['required'],
            } : {
                dpNumber: [...baseRules.dpNumber, 'required'],
                lotNumber: [...baseRules.lotNumber, 'required']
            }
        }

        return Object.assign({}, validationRules, {
            serviceAddress: Object.assign({}, validationRules.serviceAddress, baseRules, rules)
        })
    }

    isProject = () => {
        return this.props.connections.projectIsChecked
    }

    isDecommission = () => {
        return typeof this.props.services.permanentDisconnectionIsChecked !== 'undefined' && this.props.services.permanentDisconnectionIsChecked
    }

    isGeneralEnquiry = () => {
        return this.props.services.generalEnquiryIsChecked
    }

    isGeneralEnquiryWithNoRequiredSiteLocation = () => {
        return this.isGeneralEnquiry() && this.props.serviceAddress.enquiryNotRelatedToSiteLocation
    }

    // start icp-number requirements -- note addition of callback
    setParentState = (obj, callback = null) => {
        this.setState(obj, callback)
    }
    // end icp-number requirements

    variableAddressIdentityFieldsRequired = () => {
        const checkedConnections = getCheckedFields(this.props.connections)
        return checkedConnections.includes('electricity') || checkedConnections.includes('bts') || checkedConnections.includes('newSubdivision')
    }

    getNextLocation = () => {
        const checkedConnections = getCheckedFields(this.props.connections)
        const shouldGoToConfirmLocation =
            (
                !existsGet(this.props, 'serviceAddress.addressManuallyAdded', false) &&
                !this.props.serviceAddress.enquiryNotRelatedToSiteLocation &&
                hasCoordinates(this.props.serviceAddress)
            )
            /*
            check that "manually add address" hasn't occurred and coordinates were set
            do not need to check for google bypass on this, as there is no manual add option.
            Could potentially have been set if going back/forward between service types without submitting
            */
            || this.isProject()

        if (shouldGoToConfirmLocation)
            return '/confirm-location'

        if (this.direction === 'summary')
            return '/summary'

        if (this.isGeneralEnquiry())
            return '/enquiry-details'

        const nextNonConnectionService = getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)
        if (checkedConnections.length === 0 && nextNonConnectionService !== null)
            return nextNonConnectionService

        if (!shouldShowPropertyType(checkedConnections))
            return '/prerequisites'

        return '/property-type'
    }

    getLDPLDHelperModalText = (type) => {
        return <div>
            <p>To find you properties {type === 'lot-dp' ? 'Lot & DP' : 'legal description'} follow the links below and
                search for your address.</p>
            <p><Link link='https://www.kaipara.govt.nz/services/rates/rates-property-search' text='Click here'/> for
                Kaipara District </p>
            <p><Link link='https://gismaps.wdc.govt.nz/GISMapsViewer/?map=3422a1e4fb8c4242b7a76ed37d88e859'
                     text='Click here'/> for Whangarei District</p>
        </div>
    }

    getVariableAddressIdentityFieldsWithContent = () => {
        if (!this.hasAddressData())
            return []

        const relevantAddressFields = [
            'number',
            'dairyNumber',
            'dpNumber',
            'lotNumber',
            'legalDescription'
        ]

        let result = []

        const services = Object.keys(this.props.serviceAddress).filter((name) => relevantAddressFields.includes(name))
        for (const service in services) {
            const name = services[service]
            if (this.props.serviceAddress[name].length > 0)
                result.push(name)
        }

        return result
    }

    hasOnlyDpNumberOrLotNumber = () => {
        const addressIdentityFieldsWithContent = this.getVariableAddressIdentityFieldsWithContent()

        return (
            addressIdentityFieldsWithContent.length === 1 && (addressIdentityFieldsWithContent.includes('dpNumber') || addressIdentityFieldsWithContent.includes('lotNumber'))
        )
    }

    variableAddressIdentityFieldsHaveContent = () => {
        const addressIdentityFieldsWithContent = this.getVariableAddressIdentityFieldsWithContent()
        return addressIdentityFieldsWithContent.length > 0
    }

    variableAddressIdentifierShouldHaveError = () => {
        return !this.isGeneralEnquiryWithNoRequiredSiteLocation() &&
            !this.variableAddressIdentityFieldsRequired() &&
            !getCheckedFields(this.props.connections).includes('project') &&
            this.props.showServiceAddressFields && // google address is completed, or manual option selected
            (
                !this.variableAddressIdentityFieldsHaveContent() ||
                this.hasOnlyDpNumberOrLotNumber()
            )
    }

    validateAndGetNextLocation = () => {
        const addressIdentityFieldsWithContent = this.getVariableAddressIdentityFieldsWithContent()
        const variableAddressIdentityFieldsHaveContent = addressIdentityFieldsWithContent.length > 0

        let updatedState = {}

        const hasOnlyDpNumberOrLotNumber = this.hasOnlyDpNumberOrLotNumber()

        if (this.state.hasOnlyDpNumberOrLotNumber !== hasOnlyDpNumberOrLotNumber) {
            updatedState.hasOnlyDpNumberOrLotNumber = hasOnlyDpNumberOrLotNumber
        }

        if (this.variableAddressIdentifierShouldHaveError()) {
            updatedState.variableAddressIdentifierHasError = true
        } else if (variableAddressIdentityFieldsHaveContent && this.state.variableAddressIdentifierHasError) {
            updatedState.variableAddressIdentifierHasError = false
        }

        let failedIcpApproval = false

        // start icp-number requirements -- custom to name-and-address
        if (this.shouldShowIcpNumber() && this.props.generalEnquiry.icpNumber.length > 0 && !this.props.icpNumbersApproved.includes(this.props.generalEnquiry.icpNumber)) {
            updatedState.triggerIcpValidation = true
            failedIcpApproval = true
        }
        // end icp-number requirements

        if (Object.keys(updatedState).length !== 0)
            this.setState(updatedState)

        // start icp-number requirements -- specific to name-and-address
        if (failedIcpApproval) {
            return false
        }
        // end icp-number requirements

        const validationPassed = validator({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.state.validationRules,
            validationErrorFields: this.validationErrorFields
        })

        if (
            validationPassed &&
            (
                this.isProject() || // infra project doesn't have normal address field, so skipping those validation rules
                this.isGeneralEnquiryWithNoRequiredSiteLocation() || // can skip address fields here
                (variableAddressIdentityFieldsHaveContent && !hasOnlyDpNumberOrLotNumber) // extra address validation
            )
        ) {
            this.props.addProgress({'path': '/name-and-address'})

            return this.getNextLocation()
        }

        this.props.removeProgress({'path': '/name-and-address'})

        return false
    }

    validateAndNavigate = () => {
        const location = this.validateAndGetNextLocation()
        if (location) {
            const historyState = this.props.fromSummary ? {fromSummary: true} : null

            this.props.updateLocation(location, historyState, this.direction)

            return true
        }

        return false
    }

    handleNavigation = (direction) => {
        // start icp-number requirements
        this.direction = direction

        if (this.direction === 'next' || this.direction === 'summary' || this.direction === 'forward') {
            return this.validateAndNavigate()
        }
        // end icp-number requirements

        if (direction === 'back') {
            this.props.removeProgress({'path': '/name-and-address'})

            this.props.updateLocation(hasNonConnectionService(this.props.services) ? '/preferred-dates' : '/', null, 'back')

            return true
        }

        if (direction === 'summary' && this.validateAndGetNextLocation()) {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    hasAddressData = () => {
        const serviceAddress = this.props.serviceAddress
        if (serviceAddress === null)
            return false

        const addressKeys = Object.keys(serviceAddress)
        for (const i in addressKeys) {
            const key = addressKeys[i]
            const detail = serviceAddress[key]
            if (detail && detail.length > 0) {
                return true
            }
        }

        return false
    }

    getHeaderTitle = () => {
        if (this.props.nameAndAddressHeaderTitleOverrideText !== null)
            return this.props.nameAndAddressHeaderTitleOverrideText

        return "Let’s get that sorted"
    }

    shouldShowIcpNumber = () => {
        return this.isGeneralEnquiry()
    }

    render() {
        let className = ''

        if (!this.props.showBottomNavigation) {
            className += 'section-margin-bottom'
        }

        if (!existsGet(this.props, 'serviceAddress.addressManuallyAdded', false)) {
            className += ' has-map'
        }

        const showValidationError = hasError(this.state, this.validationErrorFields) || this.state.variableAddressIdentifierHasError
        const checkedConnections = getCheckedFields(this.props.connections)
        const doFibreLookup = (checkedConnections.includes('bts') || checkedConnections.includes('electricity') || checkedConnections.includes('newSubdivision') || checkedConnections.includes('alteration') || this.isDecommission()) && !existsGet(this.props, 'serviceAddress.addressManuallyAdded', false)

        const renameBackNavLabelToServices = !this.props.hasConnectionsAndServices && this.props.fromServiceDeeplink

        return (
            <div id="name-and-address" className={className}>
                <Header
                    title={this.getHeaderTitle()}
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideBack={this.state.hideBack}
                            hideSummary={this.state.hideSummary} handleNavigation={this.handleNavigation}
                            renameBackNavLabelToServices={renameBackNavLabelToServices}
                            loadingForward={this.state.loadingForward}/>

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger"/>
                                {this.state.variableAddressIdentifierHasError ?
                                    <div className='variable-address-identifier-error'>
                                        <Alert text={<>Please enter your street number, dairy number
                                            or {existsGet(this.props, 'serviceAddress.cantFindLotAndDpNumber', null) ? 'legal description' : 'both your DP number and lot number'}.</>}
                                               type="danger"/>
                                    </div>
                                    : null}
                            </div>
                            : null}

                        <div className="wrapper">

                            { this.onlyNonConnection && !this.isDecommission() ?

                                <>
                                    <Alert text="Please enter your name, address and phone number. Even if you’re applying for work on behalf of someone else, please enter your own contact details." type="description" />

                                <MandatoryText/>

                                <Input
                                    label="Your first name"
                                    mandatoryIndicator={true}
                                    keyString="contacts.0.firstName"
                                    parentErrorKey="yourDetails_firstNameErrors"
                                    value={this.props.yourDetails.firstName}
                                    validationMessages={validationMessages.firstName}
                                    validationErrorsFromParent={this.state.yourDetails_firstNameErrors}
                                    validationRules={this.state.validationRules.yourDetails.firstName}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />

                                <Input
                                    label="Your surname"
                                    mandatoryIndicator={true}
                                    keyString="contacts.0.lastName"
                                    parentErrorKey="yourDetails_lastNameErrors"
                                    value={this.props.yourDetails.lastName}
                                    validationMessages={validationMessages.lastName}
                                    validationErrorsFromParent={this.state.yourDetails_lastNameErrors}
                                    validationRules={this.state.validationRules.yourDetails.lastName}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />

                                <Input
                                    label="Phone Number"
                                    type="tel"
                                    mandatoryIndicator={true}
                                    keyString="contacts.0.phoneNumber"
                                    parentErrorKey="yourDetails_phoneNumberErrors"
                                    value={this.props.yourDetails.phoneNumber}
                                    validationMessages={validationMessages.phoneNumber}
                                    validationErrorsFromParent={this.state.yourDetails_phoneNumberErrors}
                                    validationRules={this.state.validationRules.yourDetails.phoneNumber}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />

                                <Input
                                    label="Email Address"
                                    type="email"
                                    mandatoryIndicator={this.emailAddressIsRequired()}
                                    keyString="contacts.0.emailAddress"
                                    parentErrorKey="yourDetails_emailAddressErrors"
                                    value={this.props.yourDetails.emailAddress}
                                    validationMessages={validationMessages.emailAddress}
                                    validationErrorsFromParent={this.state.yourDetails_emailAddressErrors}
                                    validationRules={this.state.validationRules.yourDetails.emailAddress}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />

                                <Input
                                    label="Company Name"
                                    keyString="contacts.0.companyName"
                                    parentErrorKey="yourDetails_companyNameErrors"
                                    value={this.props.yourDetails.companyName}
                                    validationMessages={validationMessages.companyName}
                                    validationErrorsFromParent={this.state.yourDetails_companyNameErrors}
                                    validationRules={this.state.validationRules.yourDetails.companyName}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />

                                {!this.isGeneralEnquiry() ?
                                    <Dropdown
                                        label="Preferred Method of Contact"
                                        mandatoryIndicator={true}
                                        keyString="contacts.0.preferredMethodOfContact"
                                        parentErrorKey="yourDetails_preferredMethodOfContactErrors"
                                        updateStoreValue={this.props.updateStoreValue}
                                        selectedOption={this.props.yourDetails.preferredMethodOfContact}
                                        options={
                                            [
                                                {
                                                    label: 'Email',
                                                    value: 'email'
                                                },
                                                {
                                                    label: 'Phone',
                                                    value: 'phone'
                                                }
                                            ]
                                        }
                                        isSearchable={true}
                                        validationMessages={validationMessages.preferredMethodOfContact}
                                        validationErrorsFromParent={this.state.yourDetails_preferredMethodOfContactErrors}
                                        validationRules={this.state.validationRules.yourDetails.preferredMethodOfContact}
                                        setParentState={this.setParentState}
                                    />
                                        : null
                                }
                                </>
                                :
                                <>
                                    <Alert
                                        text="Please enter the physical location for this application."
                                        type="description"/>
                                </>
                            }

                            {this.isProject() ?
                                <Textarea
                                    label="Site location details"
                                    mandatoryIndicator={true}
                                    keyString="project.siteLocationDetails"
                                    parentErrorKey="project_siteLocationDetailsErrors"
                                    value={this.props.project.siteLocationDetails}
                                    validationMessages={validationMessages.project_siteLocationDetails}
                                    validationErrorsFromParent={this.state.project_siteLocationDetailsErrors}
                                    validationRules={this.state.validationRules.project.siteLocationDetails}
                                    updateStoreValue={this.props.updateStoreValue}
                                    setParentState={this.setParentState}
                                />
                                : null
                            }

                            {this.isGeneralEnquiry() ?
                                <>
                                    <IcpNumber
                                        label={<>ICP Number<Tooltip content={<p>
                                            {getIcpNumberHelperText()}
                                        </p>}/></>}
                                        keyString={"generalEnquiry.icpNumber"}
                                        parentErrorKey="generalEnquiry_icpNumberErrors"
                                        value={this.props.generalEnquiry.icpNumber}
                                        required={ false }
                                        validationErrorsFromParent={this.state.generalEnquiry_icpNumberErrors} // this is required even though there are no parent validation rules, in order for the global error message to show
                                        updateStoreValue={this.props.updateStoreValue}
                                        parentHasErrors={showValidationError}
                                        setParentState={this.setParentState}
                                        loadingForward={this.state.loadingForward}
                                        triggerIcpValidation={this.state.triggerIcpValidation}
                                        icpNumbersApproved={this.props.icpNumbersApproved}
                                        icpNumbersDenied={this.props.icpNumbersDenied}
                                        icpNumberPendingApprovalLatest={this.props.icpNumberPendingApprovalLatest}
                                    />

                                    <Checkbox
                                        label="My enquiry is not related to a site location"
                                        keyString="serviceAddress.enquiryNotRelatedToSiteLocation"
                                        updateStoreValue={this.props.updateStoreValue}
                                        isChecked={this.props.serviceAddress.enquiryNotRelatedToSiteLocation}
                                        setParentState={this.setParentState}
                                    />
                                </>
                                : null
                            }

                            {
                                (
                                    !(this.isGeneralEnquiry() && this.props.serviceAddress.enquiryNotRelatedToSiteLocation) &&
                                    !this.isProject() &&
                                    !existsGet(this.props, 'serviceAddress.addressManuallyAdded', false)
                                ) ?
                                    <GoogleAddress
                                        label={`Site location
                                            ${ this.props.isProject ? ' (Approximate)' : ''}
                                            ${ this.isDecommission() ? ' where permanent disconnection is required' : ''}`}
                                        updateStore={this.props.updateStore}
                                        updateStoreValue={this.props.updateStoreValue}
                                        serviceAddress={this.props.serviceAddress}
                                        googleServiceAddress={this.props.google.serviceAddress}
                                        validationErrorsFromParent={this.state.google_serviceAddressErrors}
                                        validationMessages={validationMessages.google_serviceAddress}
                                        setParentState={this.setParentState}
                                        fibreStatus={this.props.fibreStatus}
                                        readyPropertyIsChecked={this.props.readyPropertyIsChecked}
                                        showServiceAddressFields={this.props.showServiceAddressFields}
                                        enquiryNotRelatedToSiteLocation={this.props.serviceAddress.enquiryNotRelatedToSiteLocation}
                                        doFibreLookup={doFibreLookup}
                                        isDecommission={this.isDecommission()}
                                    />
                                    : null}

                            {(
                                !(this.isGeneralEnquiry() && this.props.serviceAddress.enquiryNotRelatedToSiteLocation) &&
                                !this.props.connections.projectIsChecked &&
                                (this.props.showServiceAddressFields || this.hasAddressData())
                            ) ?
                                <>
                                    <Input
                                        label="Unit number"
                                        keyString="serviceAddress.unit"
                                        parentErrorKey="serviceAddress_unitErrors"
                                        value={this.props.serviceAddress.unit}
                                        validationMessages={validationMessages.serviceAddress_unit}
                                        validationErrorsFromParent={this.state.serviceAddress_unitErrors}
                                        validationRules={existsGet(this.state, 'validationRules.serviceAddress.unit', null)}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Street number"
                                        keyString="serviceAddress.number"
                                        parentErrorKey="serviceAddress_numberErrors"
                                        value={this.props.serviceAddress.number}
                                        validationMessages={validationMessages.serviceAddress_number}
                                        validationErrorsFromParent={this.state.serviceAddress_numberErrors}
                                        validationRules={existsGet(this.state, 'validationRules.serviceAddress.number', null)}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Street name"
                                        mandatoryIndicator={true}
                                        keyString="serviceAddress.street"
                                        parentErrorKey="serviceAddress_streetErrors"
                                        value={this.props.serviceAddress.street}
                                        validationMessages={validationMessages.serviceAddress_street}
                                        validationErrorsFromParent={this.state.serviceAddress_streetErrors}
                                        validationRules={existsGet(this.state, 'validationRules.serviceAddress.street', null)}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Suburb"
                                        keyString="serviceAddress.suburb"
                                        parentErrorKey="serviceAddress_suburbErrors"
                                        value={this.props.serviceAddress.suburb}
                                        validationMessages={validationMessages.serviceAddress_suburb}
                                        validationErrorsFromParent={this.state.serviceAddress_suburbErrors}
                                        validationRules={existsGet(this.state, 'validationRules.serviceAddress.suburb', null)}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="City"
                                        mandatoryIndicator={true}
                                        keyString="serviceAddress.city"
                                        parentErrorKey="serviceAddress_cityErrors"
                                        value={this.props.serviceAddress.city}
                                        validationMessages={validationMessages.serviceAddress_city}
                                        validationErrorsFromParent={this.state.serviceAddress_cityErrors}
                                        validationRules={existsGet(this.state, 'validationRules.serviceAddress.city', null)}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Dairy number"
                                        keyString="serviceAddress.dairyNumber"
                                        parentErrorKey="serviceAddress_dairyNumberErrors"
                                        value={this.props.serviceAddress.dairyNumber}
                                        validationMessages={validationMessages.serviceAddress_dairyNumber}
                                        validationErrorsFromParent={this.state.serviceAddress_dairyNumberErrors}
                                        validationRules={existsGet(this.state, 'validationRules.serviceAddress.dairyNumber', null)}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    {!this.isDecommission() ?
                                        <>
                                            <div className='name-and-address__find-lot-dp-legal'>
                                        <span className='name-and-address__helper-button'
                                              onClick={() => this.setState({showLotDpHelperModal: true})}>
                                            <span className="name-and-address__helper-button-text"><span
                                                className="mr-2"><TooltipIcon/></span>{!this.isGeneralEnquiry() ? "Click here to find your property's Lot & DP Number" : "Providing this helps identify your New Connection"}</span>
                                        </span>
                                            </div>

                                            <Input
                                                label="Lot number"
                                                mandatoryIndicator={this.variableAddressIdentityFieldsRequired() && !this.props.serviceAddress.cantFindLotAndDpNumber}
                                                keyString="serviceAddress.lotNumber"
                                                parentErrorKey="serviceAddress_lotNumberErrors"
                                                value={this.props.serviceAddress.lotNumber}
                                                validationMessages={validationMessages.serviceAddress_lotNumber}
                                                validationErrorsFromParent={this.state.serviceAddress_lotNumberErrors}
                                                validationRules={existsGet(this.state, 'validationRules.serviceAddress.lotNumber', null)}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                            />

                                            <Modal
                                                title="DP & Lot Number"
                                                content={this.getLDPLDHelperModalText('lot-dp')}
                                                show={this.state.showLotDpHelperModal}
                                                setParentState={this.setParentState}
                                                closeAction={() => this.setState({showLotDpHelperModal: false})}
                                                className="legal-description-modal"
                                            />

                                            <Input
                                                label="DP number"
                                                mandatoryIndicator={this.variableAddressIdentityFieldsRequired() && !this.props.serviceAddress.cantFindLotAndDpNumber}
                                                keyString="serviceAddress.dpNumber"
                                                parentErrorKey="serviceAddress_dpNumberErrors"
                                                value={this.props.serviceAddress.dpNumber}
                                                validationMessages={validationMessages.serviceAddress_dpNumber}
                                                validationErrorsFromParent={this.state.serviceAddress_dpNumberErrors}
                                                validationRules={existsGet(this.state, 'validationRules.serviceAddress.dpNumber', null)}
                                                updateStoreValue={this.props.updateStoreValue}
                                                setParentState={this.setParentState}
                                            />

                                            <Checkbox
                                                label="My property doesn't have a Lot & DP number"
                                                keyString="serviceAddress.cantFindLotAndDpNumber"
                                                updateStoreValue={this.props.updateStoreValue}
                                                isChecked={this.props.serviceAddress.cantFindLotAndDpNumber}
                                                setParentState={this.setParentState}
                                            />

                                            {this.props.serviceAddress.cantFindLotAndDpNumber ?
                                                <>
                                                    <Modal
                                                        title="Legal Description"
                                                        content={this.getLDPLDHelperModalText('legal-desc')}
                                                        show={this.state.showLegalDescriptionModal}
                                                        setParentState={this.setParentState}
                                                        closeAction={() => this.setState({showLegalDescriptionModal: false})}
                                                        className="legal-description-modal"
                                                    />
                                                    <div className='name-and-address__find-lot-dp-legal'>
                                                <span className='name-and-address__helper-button'
                                                      onClick={() => this.setState({showLegalDescriptionModal: true})}>
                                                    <span className='name-and-address__helper-button-text'><span
                                                        className='mr-2'><TooltipIcon/></span>Click here to find your property's Legal Description</span>
                                                </span>
                                                    </div>
                                                    <Input
                                                        label={<>Legal
                                                            Description{this.variableAddressIdentityFieldsRequired() ?
                                                                <MandatoryIndicator/> : null}</>}
                                                        keyString="serviceAddress.legalDescription"
                                                        parentErrorKey="serviceAddress_legalDescriptionErrors"
                                                        value={this.props.serviceAddress.legalDescription}
                                                        validationMessages={validationMessages.serviceAddress_legalDescription}
                                                        validationErrorsFromParent={this.state.serviceAddress_legalDescriptionErrors}
                                                        validationRules={existsGet(this.state, 'validationRules.serviceAddress.legalDescription', null)}
                                                        updateStoreValue={this.props.updateStoreValue}
                                                        setParentState={this.setParentState}
                                                    />
                                                </>
                                                : null}
                                        </>
                                        : null}
                                </>
                                : null}
                        </div>
                        {this.props.showBottomNavigation && showValidationError ?
                            <div className='validation-bottom-wrapper'>
                                <Alert text={content.validation.hasError} type="danger"/>
                                {this.state.variableAddressIdentifierHasError ?
                                    <div className='variable-address-identifier-error'>
                                        <Alert text={<>Please enter your street number, dairy number
                                            or {existsGet(this.props, 'serviceAddress.cantFindLotAndDpNumber', null) ? 'legal description' : 'both your DP number and lot number'}.</>}
                                               type="danger"/>
                                    </div>
                                    : null}
                            </div>
                            : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation className='bottom-navigation' hideBack={this.state.hideBack}
                                hideSummary={this.state.hideSummary} handleNavigation={this.handleNavigation}
                                renameBackNavLabelToServices={renameBackNavLabelToServices}
                                loadingForward={this.state.loadingForward}/>
                    : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(
    React.forwardRef((props, ref) => {
        return <NameAndAddress ref={ref} {...props} />
    })
)
