export default (checkedConnections) => {
    // First handle existing special cases
    if (checkedConnections.length === 1 && (checkedConnections.includes('newSubdivision') || checkedConnections.includes('bts'))) {
        return false
    }
    else if (checkedConnections.length === 2 && (checkedConnections.includes('newSubdivision') && checkedConnections.includes('bts'))) {
        return false
    }

    // Then check if it's ONLY permanentDecommission
    return !(checkedConnections.length === 1 && checkedConnections.includes('permanentDisconnection'));


}
