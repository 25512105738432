import React, {Component} from 'react'
import TextInputWrapper from '../../../hocs/text-input-wrapper'
import MandatoryIndicator from "../mandatory-indicator/mandatory-indicator"

class Input extends Component {
    render() {
        const type = typeof this.props.type !== 'undefined' ? this.props.type : 'text'
        const step = typeof this.props.step !== 'undefined' ? this.props.step : null
        const min = typeof this.props.min !== 'undefined' ? this.props.min : 0
        const max = typeof this.props.max !== 'undefined' ? this.props.max : null
        const maxLength = typeof this.props.maxLength !== 'undefined' ? this.props.maxLength : null

        const onWheel = (e) => {
            if (type === 'number')
                e.target.blur()
        }

        return (
            <>
                <label htmlFor={this.props.id}>{this.props.mandatoryIndicator ?
                    <MandatoryIndicator>{this.props.label}</MandatoryIndicator> : this.props.label}</label>

                {this.props.description ? this.props.description : null}
                <input
                    type={type}
                    id={this.props.id}
                    className="form-control"
                    onChange={this.props.handleChange}
                    placeholder={this.props.placeholder}
                    step={type === 'number' && step ? step : undefined}
                    min={type === 'number' && min ? min : undefined}
                    max={type === 'number' && max ? max : undefined}
                    maxLength={type === 'text' && maxLength ? maxLength : undefined}
                    value={this.props.value}
                    onWheel={onWheel}
                />
            </>
        )
    }
}

export default TextInputWrapper(Input)
