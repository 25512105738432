import React, {Component} from 'react'
import {connect} from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import Navigation from "../../common/navigation"
import Alert from "../../common/alert"
import Dropdown from "../../common/dropdown"
import Checkbox from "../../common/checkbox"
import validate from "../../../services/validator/validator"
import Input from "../../common/input/input"
import CheckboxRadio from "../../common/checkbox-radio"
import Header from "../../common/header"
import getCheckedFields from "../../../helpers/get-checked-fields"
import Link from "../../../components/common/link"
import {getNextNonConnectionService} from "../../../services/router/non-connection-service"
import MandatoryText from "../../common/mandatory-text/mandatory-text"
import StandardTable from "../../common/standard-table"
import getOnsiteGenerationPriceRows from "../../../helpers/get-onsite-generation-price-rows"
import getPriceList from "../../../helpers/get-price-list"
import convertArrayToDropdownFormat from "../../../helpers/convert-array-to-dropdown-format"
import hasError from "../../../helpers/has-error"

const links = require('../../../json/links/links.json')

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        fromSummary: existsGet(state, 'historyData.state.fromSummary', false),
        services: existsGet(state, 'services', null),
        onsiteGeneration: {

            type: existsGet(state, 'onsiteGeneration.type', null),
            connectionType: existsGet(state, 'onsiteGeneration.connectionType', null),

            generationCapacityKw: existsGet(state, 'onsiteGeneration.generationCapacityKw', ''),
            phases: existsGet(state, 'onsiteGeneration.phases', null),
            voltage: existsGet(state, 'onsiteGeneration.voltage', null),
            storageType: existsGet(state, 'onsiteGeneration.storageType', null),
            storageTypeBatteryHasInverter: existsGet(state, 'onsiteGeneration.storageTypeBatteryHasInverter', null),
            inverterManufacturer: existsGet(state, 'onsiteGeneration.inverterManufacturer', null),
            inverterModel: existsGet(state, 'onsiteGeneration.inverterModel', ''),
            compliant: existsGet(state, 'onsiteGeneration.compliant', false),

            // battery generation tech details
            storageCapacityKwh: existsGet(state, 'onsiteGeneration.storageCapacityKwh', ''), // both battery generation and battery storage
            storageGenerationPhase: existsGet(state, 'onsiteGeneration.storageGenerationPhase', null),
            storageConnectionVoltage: existsGet(state, 'onsiteGeneration.storageConnectionVoltage', null),
            storageChargeCurrentKw: existsGet(state, 'onsiteGeneration.storageChargeCurrentKw', ''),
            storageDischargeCurrentKw: existsGet(state, 'onsiteGeneration.storageDischargeCurrentKw', ''),
            storageInverterManufacturer: existsGet(state, 'onsiteGeneration.storageInverterManufacturer', null),
            storageInverterModel: existsGet(state, 'onsiteGeneration.storageInverterModel', ''),
            storageCompliant: existsGet(state, 'onsiteGeneration.storageCompliant', false),
        },
        content: {
            inverterManufacturerPicklist: existsGet(state, 'content.inverterManufacturerPicklist', null),
        },
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked')
        },
        propertyType: existsGet(state, 'propertyType', null)
    }
}

const validationMessages = {
    'onsiteGeneration_type': {
        'required': 'Please select your generation type.'
    },
    'onsiteGeneration_connectionType': {
        'required': 'Please select a connection type.'
    },
    'onsiteGeneration_generationCapacityKw': {
        'requiredWith': 'Please enter the generation capacity kW.',
        'maxValue': 'Maximum allowable capacity is 1000 kW.'
    },
    'onsiteGeneration_phases': {
        'requiredWith': 'Please select the number of phases generating.'
    },
    'onsiteGeneration_voltage': {
        'requiredWith': 'Please select the connection voltage.'
    },
    'onsiteGeneration_storageType': {
        'requiredWith': 'Please select your storage type.'
    },
    'onsiteGeneration_storageCapacityKwh': {
        'required': 'Please enter the storage capacity in kWh.',
        'maxValue': 'Maximum allowable capacity is 1000 kWh.'
    },
    'onsiteGeneration_storageTypeBatteryHasInverter': {
        'requiredWith': 'Please select if your battery has an inverter.'
    },
    'onsiteGeneration_inverterManufacturer': {
        'requiredWith': 'Please select your inverter manufacturer.'
    },
    'onsiteGeneration_inverterModel': {
        'requiredWith': 'Please enter your inverter model.'
    },
    'onsiteGeneration_storageGenerationPhase': {
        'requiredWith': 'Please select the number of phases generating.'
    },
    'onsiteGeneration_storageConnectionVoltage': {
        'requiredWith': 'Please select the connection voltage.'
    },
    'onsiteGeneration_storageChargeCurrentKw': {
        'requiredWith': 'Please select the charge current in Amps.',
        'maxValue': 'Maximum allowable charge is 1000 kW.'
    },
    'onsiteGeneration_storageDischargeCurrentKw': {
        'requiredWith': 'Please select the discharge current in Amps.',
        'maxValue': 'Maximum allowable discharge is 1000 kW.'
    },
    'onsiteGeneration_storageInverterManufacturer': {
        'requiredWith': 'Please select your inverter manufacturer.'
    },
    'onsiteGeneration_storageInverterModel': {
        'requiredWith': 'Please enter your inverter model.'
    },
    'onsiteGeneration_storageCompliant': {
        'requiredWith': 'Please confirm your inverter model meets the AS/NZS 4777.2:2020 standard.'
    }
}

const content = require('../../../json/content/content.json')

class DistributedGenerationCapacity extends Component {
    constructor(props) {
        super(props)

        this.validationRules = {

            onsiteGeneration: {
                type: ['required'],
                connectionType: ['required'],
                generationCapacityKw: [
                    {'requiredWith': 'type:Photo Voltaic'},
                    {'requiredWith': 'type:Wind'},
                    {'requiredWith': 'type:Other'},
                    {'maxValue': 1000}],
                phases: [
                    {'requiredWith': 'type:Photo Voltaic'},
                    {'requiredWith': 'type:Wind'},
                    {'requiredWith': 'type:Other'}
                ],
                voltage: [
                    {'requiredWith': 'type:Photo Voltaic'},
                    {'requiredWith': 'type:Wind'},
                    {'requiredWith': 'type:Other'}
                ],
                storageType: [
                    {'requiredWith': 'type:Photo Voltaic'},
                    {'requiredWith': 'type:Wind'},
                    {'requiredWith': 'type:Other'},
                    {'bypassRequiredIf': 'type:Battery'}],
                storageCapacityKwh: [
                    'required',
                    {'bypassRequiredIf': 'storageType:No storage'},
                    {'maxValue': 1000}],
                storageTypeBatteryHasInverter: [{'requiredWith': 'storageType:Battery'}],
                storageGenerationPhase: [{'requiredWith': 'type:Battery'}],
                storageConnectionVoltage: [{'requiredWith': 'type:Battery'}],
                storageChargeCurrentKw: [
                    {'requiredWith': 'type:Battery'},
                    {'requiredWith': 'storageType:Battery'},
                    {'maxValue': 1000}
                ],
                storageDischargeCurrentKw: [
                    {'requiredWith': 'type:Battery'},
                    {'requiredWith': 'storageType:Battery'},
                    {'maxValue': 1000}
                ]
            }
        }

        this.inverterManufacturerContentLoaded =
            this.props.content.inverterManufacturerPicklist !== null && this.props.content.inverterManufacturerPicklist.length > 0

        if (this.inverterManufacturerContentLoaded) {
            this.validationRules.onsiteGeneration = Object.assign({},
                this.validationRules.onsiteGeneration,
                {
                    inverterManufacturer: [
                        {'requiredWith': 'type:Photo Voltaic'},
                        {'requiredWith': 'type:Wind'},
                        {'requiredWith': 'type:Other'}
                    ],
                    inverterModel: [
                        {'requiredWith': 'type:Photo Voltaic'},
                        {'requiredWith': 'type:Wind'},
                        {'requiredWith': 'type:Other'}
                    ],
                    storageInverterManufacturer: [
                        {'requiredWith': 'type:Battery'},
                        {'requiredWith': 'storageTypeBatteryHasInverter:Yes'}
                    ],
                    storageInverterModel: [
                        {'requiredWith': 'type:Battery'},
                        {'requiredWith': 'storageTypeBatteryHasInverter:Yes'}
                    ],
                }
            )
        }

        this.validationErrorFields = {
            onsiteGeneration_typeErrors: [],
            onsiteGeneration_connectionTypeErrors: [],
            onsiteGeneration_generationCapacityKwErrors: [],
            onsiteGeneration_phasesErrors: [],
            onsiteGeneration_voltageErrors: [],
            onsiteGeneration_storageTypeErrors: [],
            onsiteGeneration_storageCapacityKwhErrors: [],
            onsiteGeneration_storageTypeBatteryHasInverterErrors: [],
            onsiteGeneration_inverterManufacturerErrors: [],
            onsiteGeneration_inverterModelErrors: [],
            onsiteGeneration_storageGenerationPhaseErrors: [],
            onsiteGeneration_storageConnectionVoltageErrors: [],
            onsiteGeneration_storageChargeCurrentKwErrors: [],
            onsiteGeneration_storageDischargeCurrentKwErrors: [],
            onsiteGeneration_storageInverterManufacturerErrors: [],
            onsiteGeneration_storageInverterModelErrors: [],
        }

        this.state = {
            ...this.validationErrorFields,
            hideSummary: !this.props.fromSummary
        }
    }

    componentDidMount() {
        const inverterManufacturer = this.props.onsiteGeneration.inverterManufacturer
        if (inverterManufacturer) {
            const hasValidInverterManufacturer = this.props.content.inverterManufacturerPicklist.includes(inverterManufacturer)
            if (!hasValidInverterManufacturer) {
                this.props.updateStoreValue('onsiteGeneration.inverterManufacturer', null)
            }
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward') {
            this.validateAndNavigate(direction)

            return true
        }

        if (direction === 'back') {
            this.props.removeProgress({'path': '/distributed-generation-capacity'})

            const checkedConnections = getCheckedFields(this.props.connections)
            const caravanOrOnlyBTS = this.props.propertyType === 'Caravan' || checkedConnections.length === 1 && checkedConnections[0] === 'bts'
            if ((checkedConnections.length === 1 && checkedConnections[0] === 'onsiteGeneration') || caravanOrOnlyBTS) {
                this.props.updateLocation('/approved-contractor', null, 'back')

                return true
            }

            this.props.updateLocation('/manual-capacity', null, 'back')

            return true
        }

        if (direction === 'summary' && this.validateAndGetNextLocation()) {
            this.props.updateLocation('/summary')

            return true
        }

        return false
    }

    validateAndGetNextLocation = () => {
        const passedParentValidation = validate({
            props: this.props,
            state: this.state,
            setParentState: this.setParentState,
            validationRules: this.validationRules,
            validationErrorFields: this.validationErrorFields
        })

        if (!passedParentValidation) {
            this.props.removeProgress({'path': '/distributed-generation-capacity'})
            return false
        }

        const nextNonConnectionService = getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)

        return nextNonConnectionService !== null ? nextNonConnectionService : '/other-information'
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location) {
            this.props.addProgress({'path': '/distributed-generation-capacity'})
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    getIsDomestic = () => {
        return this.props.propertyType.includes('Residential Dwelling') ||
            this.props.propertyType.includes('Caravan');
    }

    generateFeeTable = (capacity) => {
        const priceRows = getOnsiteGenerationPriceRows(getPriceList())
        const isDomestic = this.getIsDomestic()
        let key = null
        const capacityFloat = parseFloat(capacity)

        // @todo why on earth are we using string comparison to determine fees, nuts. Refactor.
        if (capacityFloat <= 10.99) {
            key = 'Up to 10kW & Residential'
        } else if (capacityFloat >= 11 && capacityFloat <= 100.99) {
            key = '11kW - 100kW'
        } else if (capacityFloat >= 101 && capacityFloat <= 1000) {
            key = '101kW - 1MW (1000kW) Max.'
        } else if (capacityFloat > 1000.01) { // in theory we don't hit this value, maxValue validation -> 1000
            key = 'More than 1MW (1000kW) Max.'
        }

        if (key === null)
            return null

        let cost = null
        priceRows.some((row) => {
            const condition = row[0]
            const value = row[1]

            if (key === condition) {
                cost = value
                return true
            }
        })

        // Residential Dwelling / Caravan, max. $100
        if (isDomestic)
            cost = '$' + 100

        return <StandardTable
            uniqueTableName='dg'
            table={{
                classes: 'table table-bordered table-striped'
            }}
            tableHeaders={{
                classes: 'table-dark',
                headers: [
                    'Service',
                    'Fee'
                ]
            }}
            tableRows={{
                rows: [
                    ['Installation' + (isDomestic ? ' (Residential)' : ''), cost]
                ]
            }}
        />
    }

    componentDidUpdate(prevProps) {

        const prevHasBatteryType =
            prevProps.onsiteGeneration.type && prevProps.onsiteGeneration.type !== '' && prevProps.onsiteGeneration.type === 'Battery';
        const prevHasOtherTypes =
            prevProps.onsiteGeneration.type && prevProps.onsiteGeneration.type !== '' && prevProps.onsiteGeneration.type !== 'Battery';

        const currentHasBatteryType =
            this.props.onsiteGeneration.type && this.props.onsiteGeneration.type !== '' && this.props.onsiteGeneration.type === 'Battery';
        const currentHasOtherTypes =
            this.props.onsiteGeneration.type && this.props.onsiteGeneration.type !== '' && this.props.onsiteGeneration.type !== 'Battery';

        const prevHasBatteryHasInverter =
            !prevHasBatteryType && prevHasOtherTypes &&
            prevProps.onsiteGeneration.storageType && prevProps.onsiteGeneration.storageType !== '' && prevProps.onsiteGeneration.storageType === 'Battery';
        const currentHasBatteryHasInverter =
            !currentHasBatteryType && currentHasOtherTypes &&
            this.props.onsiteGeneration.storageType && this.props.onsiteGeneration.storageType !== '' && this.props.onsiteGeneration.storageType === 'Battery';

        const prevHasBatteryStorageInverter =
            prevProps.onsiteGeneration.storageTypeBatteryHasInverter &&
            prevProps.onsiteGeneration.storageTypeBatteryHasInverter !== '' && prevProps.onsiteGeneration.storageTypeBatteryHasInverter === 'Yes';
        const currentHasBatteryStorageInverter =
            this.props.onsiteGeneration.storageTypeBatteryHasInverter &&
            this.props.onsiteGeneration.storageTypeBatteryHasInverter !== '' && this.props.onsiteGeneration.storageTypeBatteryHasInverter === 'Yes';


        // If user switched from having battery has inverter to not having it
        if (prevHasBatteryHasInverter && !currentHasBatteryHasInverter) {
            // Clear Battery has inverter related props
            this.props.updateStoreValue('onsiteGeneration.storageTypeBatteryHasInverter', null);
            this.props.updateStoreValue('onsiteGeneration.storageInverterManufacturer', null);
            this.props.updateStoreValue('onsiteGeneration.storageInverterModel', '');
            this.props.updateStoreValue('onsiteGeneration.storageCompliant', false);
        }

        // If user switched from having battery storage inverter to not having it
        if (prevHasBatteryStorageInverter && !currentHasBatteryStorageInverter) {
            // Clear Battery storage inverter related props
            this.props.updateStoreValue('onsiteGeneration.storageInverterManufacturer', null);
            this.props.updateStoreValue('onsiteGeneration.storageInverterModel', '');
            this.props.updateStoreValue('onsiteGeneration.storageCompliant', false);
        }

        // If user switched from having other types to not having them
        if (prevHasOtherTypes && !currentHasOtherTypes) {
            // Clear PV/Wind/Other related props
            this.props.updateStoreValue('onsiteGeneration.generationCapacityKw', '');
            this.props.updateStoreValue('onsiteGeneration.phases', null);
            this.props.updateStoreValue('onsiteGeneration.voltage', null);
            this.props.updateStoreValue('onsiteGeneration.storageType', '');
            this.props.updateStoreValue('onsiteGeneration.storageCapacityKwh', '');  // update regardless
            this.props.updateStoreValue('onsiteGeneration.storageTypeBatteryHasInverter', null);
            this.props.updateStoreValue('onsiteGeneration.inverterManufacturer', null);
            this.props.updateStoreValue('onsiteGeneration.inverterModel', '');
            this.props.updateStoreValue('onsiteGeneration.compliant', false);
        }

        // If user switched from having battery type to not having it
        if (prevHasBatteryType && !currentHasBatteryType) {
            // Clear Battery related props
            this.props.updateStoreValue('onsiteGeneration.storageCapacityKwh', ''); // update regardless
            this.props.updateStoreValue('onsiteGeneration.storageGenerationPhase', null);
            this.props.updateStoreValue('onsiteGeneration.storageConnectionVoltage', null);
            this.props.updateStoreValue('onsiteGeneration.storageChargeCurrentKw', '');
            this.props.updateStoreValue('onsiteGeneration.storageDischargeCurrentKw', '');
            this.props.updateStoreValue('onsiteGeneration.storageInverterManufacturer', null);
            this.props.updateStoreValue('onsiteGeneration.storageInverterModel', '');
            this.props.updateStoreValue('onsiteGeneration.storageCompliant', false);
        }
    }


    render() {

        const showValidationError = hasError(this.state, this.validationErrorFields)
        const hasBatteryGenerationType =
            this.props.onsiteGeneration.type && this.props.onsiteGeneration.type !== '' && this.props.onsiteGeneration.type === 'Battery'
        const hasBatteryStorageType =
            this.props.onsiteGeneration.storageType && this.props.onsiteGeneration.storageType !== '' && this.props.onsiteGeneration.storageType === 'Battery'
        const hasOtherGenerationTypes =
            this.props.onsiteGeneration.type && this.props.onsiteGeneration.type !== '' && this.props.onsiteGeneration.type !== 'Battery'
        const hasBatteryHasInverter =
            !hasBatteryGenerationType && hasOtherGenerationTypes &&
            this.props.onsiteGeneration.storageType && this.props.onsiteGeneration.storageType !== '' && this.props.onsiteGeneration.storageType === 'Battery'
        const hasBatteryStorageInverter =
            this.props.onsiteGeneration.storageTypeBatteryHasInverter && this.props.onsiteGeneration.storageTypeBatteryHasInverter !== '' && this.props.onsiteGeneration.storageTypeBatteryHasInverter === 'Yes'

        return (
            <div id="on-site-generation" className="section-margin-bottom">
                <Header
                    title="Tell us more about your intended alternative energy setup"
                />

                <Navigation isTop={true} setParentState={this.setParentState} hideSummary={this.state.hideSummary}
                            handleNavigation={this.handleNavigation}/>

                <div ref={this.props.sectionWrapperRef} className="section">
                    <div className="container single-col">
                        {showValidationError ?
                            <div className="validation-top-wrapper">
                                <Alert text={content.validation.hasError} type="danger"/>
                            </div>
                            : null}

                        <Alert type="description" text={
                            <>
                                The information you give us here helps to determine your connection requirements.
                            </>
                        }/>

                        <div className="wrapper">
                            <MandatoryText/>

                            <CheckboxRadio
                                label="Alternative energy generation type"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.type"
                                parentErrorKey="onsiteGeneration_typeErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.type}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_type}
                                validationErrorsFromParent={this.state.onsiteGeneration_typeErrors}
                                checkboxes={
                                    [
                                        {
                                            'label': 'Photo Voltaic Solar (with or without Battery)',
                                            'value': 'Photo Voltaic'
                                        },
                                        {'label': 'Wind', 'value': 'Wind'},
                                        {'label': 'Battery only installation', 'value': 'Battery'},
                                        {'label': 'Other', 'value': 'Other'}
                                    ]
                                }
                                initialTitleColor='gray'
                                multiSelect={false}
                            />

                            <CheckboxRadio
                                label="Connection Type"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.connectionType"
                                parentErrorKey="onsiteGeneration_connectionTypeErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.connectionType}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_connectionType}
                                validationErrorsFromParent={this.state.onsiteGeneration_connectionTypeErrors}
                                checkboxes={
                                    [
                                        {'label': 'New Connection', 'value': 'New Connection'},
                                        {
                                            'label': 'Expand existing alternative energy generation',
                                            'value': 'Expand Existing Generation'
                                        },
                                        {
                                            'label': 'Decommission an alternate energy connection',
                                            'value': 'Decommission Generation'
                                        }
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            <Input
                                show={hasOtherGenerationTypes}
                                label="Generating Capacity (kW)"
                                mandatoryIndicator={true}
                                type="number"
                                min={0.1}
                                max={1000}
                                step={0.1}
                                keyString={"onsiteGeneration.generationCapacityKw"}
                                parentErrorKey="onsiteGeneration_generationCapacityKwErrors"
                                value={this.props.onsiteGeneration.generationCapacityKw}
                                validationMessages={validationMessages.onsiteGeneration_generationCapacityKw}
                                validationErrorsFromParent={this.state.onsiteGeneration_generationCapacityKwErrors}
                                validationRules={this.validationRules.onsiteGeneration.generationCapacityKw}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            {
                                hasOtherGenerationTypes &&
                                this.props.onsiteGeneration.generationCapacityKw !== null &&
                                this.props.onsiteGeneration.generationCapacityKw > 0 ?
                                    this.generateFeeTable(this.props.onsiteGeneration.generationCapacityKw) :
                                    null
                            }

                            <CheckboxRadio
                                show={hasOtherGenerationTypes}
                                label="Number of Phases Generating"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.phases"
                                parentErrorKey="onsiteGeneration_phasesErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.phases}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_phases}
                                validationErrorsFromParent={this.state.onsiteGeneration_phasesErrors}
                                checkboxes={
                                    [
                                        {'label': '1', 'value': '1'},
                                        {'label': '2', 'value': '2'},
                                        {'label': '3', 'value': '3'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            <CheckboxRadio
                                show={hasOtherGenerationTypes}
                                label="Connection Voltage"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.voltage"
                                parentErrorKey="onsiteGeneration_voltageErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.voltage}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_voltage}
                                validationErrorsFromParent={this.state.onsiteGeneration_voltageErrors}
                                checkboxes={
                                    [
                                        {'label': '230/400V', 'value': '230/400V'},
                                        {'label': 'Other', 'value': 'other'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            {hasOtherGenerationTypes && this.inverterManufacturerContentLoaded ?
                                <>
                                    <Dropdown
                                        label="Inverter Manufacturer"
                                        mandatoryIndicator={true}
                                        keyString="onsiteGeneration.inverterManufacturer"
                                        parentErrorKey="onsiteGeneration_inverterManufacturerErrors"
                                        updateStoreValue={this.props.updateStoreValue}
                                        selectedOption={this.props.onsiteGeneration.inverterManufacturer}
                                        options={convertArrayToDropdownFormat(this.props.content.inverterManufacturerPicklist)}
                                        isSearchable={true}
                                        validationMessages={validationMessages.onsiteGeneration_inverterManufacturer}
                                        validationErrorsFromParent={this.state.onsiteGeneration_inverterManufacturerErrors}
                                        validationRules={this.validationRules.onsiteGeneration.inverterManufacturer}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Inverter Model"
                                        mandatoryIndicator={true}
                                        keyString="onsiteGeneration.inverterModel"
                                        parentErrorKey="onsiteGeneration_inverterModelErrors"
                                        value={this.props.onsiteGeneration.inverterModel}
                                        validationMessages={validationMessages.onsiteGeneration_inverterModel}
                                        validationErrorsFromParent={this.state.onsiteGeneration_inverterModelErrors}
                                        validationRules={this.validationRules.onsiteGeneration.inverterModel}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Checkbox
                                        label="I confirm that my inverter is identified on the list of compliant inverters on the Australian Clean Energy Council website."
                                        keyString="onsiteGeneration.compliant"
                                        updateStoreValue={this.props.updateStoreValue}
                                        isChecked={this.props.onsiteGeneration.compliant}
                                        setParentState={this.setParentState}
                                    />

                                    <p style={{marginBottom: '2rem'}}>
                                        You can find the list of compliant inverters on the <Link
                                        text="Australian Clean Energy Council website" link={links.cleanEnergyCouncil}/>.
                                    </p>
                                </>
                                : null}

                            {hasOtherGenerationTypes && !hasBatteryGenerationType &&
                                <Dropdown
                                    label="Energy storage type"
                                    mandatoryIndicator={true}
                                    keyString="onsiteGeneration.storageType"
                                    parentErrorKey="onsiteGeneration_storageTypeErrors"
                                    updateStoreValue={this.props.updateStoreValue}
                                    selectedOption={this.props.onsiteGeneration.storageType}
                                    options={
                                        [
                                            {'label': 'Battery', 'value': 'Battery'},
                                            {'label': 'Other', 'value': 'Other'},
                                            {'label': 'No storage', 'value': 'No storage'}
                                        ]
                                    }
                                    isSearchable={false}
                                    validationMessages={validationMessages.onsiteGeneration_storageType}
                                    validationErrorsFromParent={this.state.onsiteGeneration_storageTypeErrors}
                                    validationRules={this.validationRules.onsiteGeneration.storageType}
                                    setParentState={this.setParentState}
                                />
                            }

                            <Input
                                show={
                                    ( hasBatteryGenerationType ||
                                        ( hasOtherGenerationTypes && this.props.onsiteGeneration.storageType && this.props.onsiteGeneration.storageType !== 'No storage' ))
                                }
                                label="Energy Storage Capacity (kWh)"
                                mandatoryIndicator={true}
                                type="number"
                                min={0.1}
                                step={0.1}
                                keyString="onsiteGeneration.storageCapacityKwh"
                                parentErrorKey="onsiteGeneration_storageCapacityKwhErrors"
                                value={this.props.onsiteGeneration.storageCapacityKwh}
                                validationMessages={validationMessages.onsiteGeneration_storageCapacityKwh}
                                validationErrorsFromParent={this.state.onsiteGeneration_storageCapacityKwhErrors}
                                validationRules={this.validationRules.onsiteGeneration.storageCapacityKwh}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            <Input
                                show={hasBatteryGenerationType || hasBatteryStorageType}
                                label="Charge Rate (kW)"
                                mandatoryIndicator={true}
                                type="number"
                                min={0.1}
                                step={0.1}
                                keyString="onsiteGeneration.storageChargeCurrentKw"
                                parentErrorKey="onsiteGeneration_storageChargeCurrentKwErrors"
                                value={this.props.onsiteGeneration.storageChargeCurrentKw}
                                validationMessages={validationMessages.onsiteGeneration_storageChargeCurrentKw}
                                validationErrorsFromParent={this.state.onsiteGeneration_storageChargeCurrentKwErrors}
                                validationRules={this.validationRules.onsiteGeneration.storageChargeCurrentKw}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            <Input
                                show={hasBatteryGenerationType || hasBatteryStorageType}
                                label="Discharge Rate (kW)"
                                mandatoryIndicator={true}
                                type="number"
                                min={0.1}
                                step={0.1}
                                keyString="onsiteGeneration.storageDischargeCurrentKw"
                                parentErrorKey="onsiteGeneration_storageDischargeCurrentKwErrors"
                                value={this.props.onsiteGeneration.storageDischargeCurrentKw}
                                validationMessages={validationMessages.onsiteGeneration_storageDischargeCurrentKw}
                                validationErrorsFromParent={this.state.onsiteGeneration_storageDischargeCurrentKwErrors}
                                validationRules={this.validationRules.onsiteGeneration.storageDischargeCurrentKw}
                                updateStoreValue={this.props.updateStoreValue}
                                setParentState={this.setParentState}
                            />

                            {
                                hasBatteryGenerationType && !hasOtherGenerationTypes &&
                                this.props.onsiteGeneration.storageDischargeCurrentKw !== null &&
                                this.props.onsiteGeneration.storageDischargeCurrentKw > 0 ?
                                    this.generateFeeTable(this.props.onsiteGeneration.storageDischargeCurrentKw) :
                                    null
                            }

                            <CheckboxRadio
                                show={hasBatteryHasInverter}
                                label="Does your Battery have an Inverter?"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.storageTypeBatteryHasInverter"
                                parentErrorKey="onsiteGeneration_storageTypeBatteryHasInverterErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.storageTypeBatteryHasInverter}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_storageTypeBatteryHasInverter}
                                validationErrorsFromParent={this.state.onsiteGeneration_storageTypeBatteryHasInverterErrors}
                                checkboxes={
                                    [
                                        {'label': 'Yes', 'value': 'Yes'},
                                        {'label': 'No', 'value': 'No'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            {hasBatteryGenerationType &&
                                <Alert type="description"
                                       text={
                                           <div className="wrapper" style={{display: 'flex', flexDirection: 'column'}}>
                                               <h3>Battery Details</h3>
                                               <div>The following technical information is required for any battery that
                                                   includes an inverter and can generate energy.
                                               </div>
                                           </div>
                                       }/>
                            }

                            <CheckboxRadio
                                show={hasBatteryGenerationType}
                                label="Number of Phases"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.storageGenerationPhase"
                                parentErrorKey="onsiteGeneration_storageGenerationPhaseErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.storageGenerationPhase}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_storageGenerationPhase}
                                validationErrorsFromParent={this.state.onsiteGeneration_storageGenerationPhaseErrors}
                                checkboxes={
                                    [
                                        {'label': '1', 'value': '1'},
                                        {'label': '2', 'value': '2'},
                                        {'label': '3', 'value': '3'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            <CheckboxRadio
                                show={hasBatteryGenerationType}
                                label="Connection Voltage"
                                mandatoryIndicator={true}
                                keyString="onsiteGeneration.storageConnectionVoltage"
                                parentErrorKey="onsiteGeneration_storageConnectionVoltageErrors"
                                updateStoreValue={this.props.updateStoreValue}
                                value={this.props.onsiteGeneration.storageConnectionVoltage}
                                setParentState={this.setParentState}
                                validationMessages={validationMessages.onsiteGeneration_storageConnectionVoltage}
                                validationErrorsFromParent={this.state.onsiteGeneration_storageConnectionVoltageErrors}
                                checkboxes={
                                    [
                                        {'label': '230/400V', 'value': '230/400V'},
                                        {'label': 'Other', 'value': 'other'}
                                    ]
                                }
                                initialTitleColor='gray'
                            />

                            {(hasBatteryGenerationType || hasBatteryStorageInverter) && this.inverterManufacturerContentLoaded ?
                                <>
                                    <Dropdown
                                        label="Inverter Manufacturer"
                                        mandatoryIndicator={true}
                                        keyString="onsiteGeneration.storageInverterManufacturer"
                                        parentErrorKey="onsiteGeneration_storageInverterManufacturerErrors"
                                        updateStoreValue={this.props.updateStoreValue}
                                        selectedOption={this.props.onsiteGeneration.storageInverterManufacturer}
                                        options={convertArrayToDropdownFormat(this.props.content.inverterManufacturerPicklist)}
                                        isSearchable={true}
                                        validationMessages={validationMessages.onsiteGeneration_inverterManufacturer}
                                        validationErrorsFromParent={this.state.onsiteGeneration_storageInverterManufacturerErrors}
                                        validationRules={this.validationRules.onsiteGeneration.storageInverterManufacturer}
                                        setParentState={this.setParentState}
                                    />

                                    <Input
                                        label="Inverter Model"
                                        mandatoryIndicator={true}
                                        keyString="onsiteGeneration.storageInverterModel"
                                        parentErrorKey="onsiteGeneration_storageInverterModelErrors"
                                        value={this.props.onsiteGeneration.storageInverterModel}
                                        validationMessages={validationMessages.onsiteGeneration_inverterModel}
                                        validationErrorsFromParent={this.state.onsiteGeneration_storageInverterModelErrors}
                                        validationRules={this.validationRules.onsiteGeneration.storageInverterModel}
                                        updateStoreValue={this.props.updateStoreValue}
                                        setParentState={this.setParentState}
                                    />

                                    <Checkbox
                                        label="I confirm that the battery inverter is identified on the list of compliant inverters on the Australian Clean Energy Council website."
                                        keyString="onsiteGeneration.storageCompliant"
                                        updateStoreValue={this.props.updateStoreValue}
                                        isChecked={this.props.onsiteGeneration.storageCompliant}
                                        setParentState={this.setParentState}
                                    />

                                    <p>
                                        You can find the list of compliant inverters on the <Link
                                        text="Australian Clean Energy Council website" link={links.cleanEnergyCouncil}/>.
                                    </p>

                                </>
                                : null}


                        </div>

                        {this.props.showBottomNavigation && showValidationError ?
                            <div className="validation-bottom-wrapper">
                                <Alert text={content.validation.hasError} type="danger"/>
                            </div>
                            : null}
                    </div>
                </div>

                {this.props.showBottomNavigation ?
                    <Navigation hideSummary={this.state.hideSummary} handleNavigation={this.handleNavigation}/>
                    : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {forwardRef: true}
)(
    React.forwardRef((props, ref) => {
        return <DistributedGenerationCapacity ref={ref} {...props} />
    })
)
