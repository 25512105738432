import React, {Component} from 'react'
import { connect } from "react-redux"
import mapDispatchToProps from "../../../helpers/map-dispatch-to-props"
import existsGet from "../../../helpers/exists-get"
import Button from "../../common/button"
import Modal from "../../common/modal"
import getAllRoles from "../../../services/roles/get-all-roles"
import Navigation from "../../common/navigation"
import getCheckedFields from "../../../helpers/get-checked-fields"
import getRemainingMandatoryRoles from "../../../services/roles/get-remaining-mandatory-roles"
import Alert from "../../common/alert"
import Header from "../../common/header"
import ContactRow from "./contact-row"
import {getNextNonConnectionService} from "../../../services/router/non-connection-service"
import MandatoryIndicator from "../../common/mandatory-indicator"
import getMandatoryRoles from "../../../services/roles/get-mandatory-roles"
import getOptionalRoles from "../../../services/roles/get-optional-roles"
import getContactNameByContactIdOrCopyFromContact from "../../../helpers/get-name-from-contact-id-or-copy-from-contact"
import MandatoryText from "../../common/mandatory-text/mandatory-text"
import doesHaveCopyFromContactId from "../../../helpers/does-have-copy-from-contact-id"
import getContactIdByRole from "../../../helpers/get-contact-id-by-role"
import getNextContactId from "../../../helpers/get-next-contact-id"

const mapStateToProps = (state) => {
    return {
        historyLocation: existsGet(state, 'historyData.location', '/'),
        historyAction: existsGet(state, 'historyData.action'),
        removeContactId: existsGet(state, 'historyData.state.removeContactId', null),
        forceValidate: existsGet(state, 'historyData.state.forceValidate', false),
        roles: existsGet(state, 'roles', null),
        contacts: existsGet(state, 'contacts', {}),
        firstName: existsGet(state, 'contacts.0.firstName', null),
        connections: {
            electricityIsChecked: existsGet(state, 'connections.electricityIsChecked'),
            onsiteGenerationIsChecked: existsGet(state, 'connections.onsiteGenerationIsChecked'),
            newSubdivisionIsChecked: existsGet(state, 'connections.newSubdivisionIsChecked'),
            alterationIsChecked: existsGet(state, 'connections.alterationIsChecked'),
            btsIsChecked: existsGet(state, 'connections.btsIsChecked'),
            projectIsChecked: existsGet(state, 'connections.projectIsChecked'),
            ballparkEstimateIsChecked: existsGet(state, 'connections.ballparkEstimateIsChecked')
        },
        services: {
            generalEnquiryIsChecked: existsGet(state, 'services.generalEnquiryIsChecked'),
            vegetationControlIsChecked: existsGet(state, 'services.vegetationControlIsChecked'),
            safetyDisconnectionIsChecked: existsGet(state, 'services.safetyDisconnectionIsChecked'),
            permanentDisconnectionIsChecked: existsGet(state, 'services.permanentDisconnectionIsChecked'),
            closeApproachConsentIsChecked: existsGet(state, 'services.closeApproachConsentIsChecked'),
            cableLocationIsChecked: existsGet(state, 'services.cableLocationIsChecked'),
            highLoadIsChecked: existsGet(state, 'services.highLoadIsChecked'),
        },
        rolesHubScrollPosition: existsGet(state, 'rolesHubScrollPosition', 0)
    }
}

class RolesHub extends Component {
    constructor(props) {
        super(props)

        this.rolesHubRemoveContactId = null

        // Get checked fields from connections
        const connectionFields = getCheckedFields(this.props.connections)
        // Get checked fields from services
        const serviceFields = getCheckedFields(this.props.services)
        // Merge both arrays into checkedFields, removing any duplicates
        this.connectionTypes = [...new Set([...connectionFields, ...serviceFields])]
        // this.connectionTypes = getCheckedFields(this.props.connections)

        this.state = {
            showRoleSelectModal: false,
            showDeleteContact: false,
            render: false,
            hasError: false,
            errorText: ''
        }
    }

    componentDidMount = () => {
        const removeContactId = this.props.removeContactId
        if (removeContactId !== null)
        {
            this.props.removeContact({'contactId': removeContactId})
        }

        this.updateContacts()

        if (this.props.forceValidate)
        {
            this.validateAndGetNextLocation()
        }
    }

    componentDidUpdate(prevProps) {
        if (Object.keys(prevProps.contacts).length > Object.keys(this.props.contacts).length)
        {
            this.rolesHubRemoveContactId = null
            this.setState({showDeleteContact: false})
        }
    }

    setParentState = (obj) => {
        this.setState(obj)
    }

    isProject = () => {
        return this.props.connections.projectIsChecked
    }

    isSubdivision = () => {
        return this.props.connections.newSubdivisionIsChecked
    }

    isBallparkEstimate = () => {
        return this.props.connections.ballparkEstimateIsChecked
    }

    initialiseRender = () => {
        this.setState({render: true}, () => {
            this.handleScrollTop()
        })
    }

    handleScrollTop = () => {
        document.getElementById('app').scrollTop = this.props.rolesHubScrollPosition
        this.props.updateStoreValue('rolesHubScrollPosition', 0)
    }

    updateContacts = () => {
        let allRoles = getAllRoles(this.connectionTypes)

        const roleKeys = Object.keys(this.props.roles)
        let contactIdsThatNoLongerExist = []

        if (roleKeys.length > 0)
        {
            roleKeys.forEach((contactId) => {
                const roles = this.props.roles[contactId]
                const role = roles[0]
                if (!allRoles.includes(role))
                {
                    contactIdsThatNoLongerExist.push(contactId)
                }
            })
        }

        if (contactIdsThatNoLongerExist.length > 0)
        {
            const contactKeys = Object.keys(this.props.contacts)
            contactKeys.forEach((contactId) => {
                const contact = this.props.contacts[contactId]
                if (doesHaveCopyFromContactId(contact))
                {
                    const copyFromContactId = this.props.contacts[contactId]['copyFromContactId']
                    if (contactIdsThatNoLongerExist.includes(copyFromContactId))
                    {
                        this.props.removeContact({contactId: contactId})
                    }
                }
            })
        }

        this.initialiseRender()
    }

    setScrollTop = () =>  {
        const scrollTop = document.getElementById("app").scrollTop
        this.props.updateStoreValue('rolesHubScrollPosition', scrollTop)
    }

    goToContactDetailsSection = (path, contactId, role) => {
        this.setScrollTop()
        return this.props.updateLocation(path, {contactId, role})
    }

    handleNavigation = (direction) => {
        if (direction === 'next' || direction === 'forward')
        {
            return this.validateAndNavigate(direction)
        }

        if (direction === 'back')
        {
            this.props.removeProgress({'path': '/roles-hub'})

            if (this.isProject())
            {
                this.props.updateLocation('/confirm-location', null, 'back') // no additional checks needed for manual address/coordinates

                return true
            }

            this.props.updateLocation('/prerequisites', null, 'back')

            return true
        }

        return false
    }

    handleDeleteClick = (contactId) => {
        this.rolesHubRemoveContactId = contactId
        this.setState({showDeleteContact: true})
    }

    handleDeleteConfirmClick = () => {
        this.props.removeContact({'contactId': this.rolesHubRemoveContactId})
    }

    shouldShowYourDetailsError = () => {
        const fields = ['firstName', 'lastName', 'phoneNumber', 'emailAddress', 'addressNumber', 'addressStreet', 'addressCity', 'addressPostcode', 'preferredMethodOfContact']

        for (const field in fields)
        {
            const fieldName = fields[field]
            const fieldValue = this.props.contacts[0][fieldName]
            if (fieldValue === null || fieldValue.length === 0)
            {
                return true
            }
        }

        return false
    }

    mandatoryRolesToString = (remainingMandatoryRoles, count) => {
        let result = ''
        remainingMandatoryRoles.forEach((role, i) => {
            if (count-2 === i)
            {
                result += role+' and '
            }
            else if (count-1 === i)
            {
                result += role
            }
            else
            {
                result += role+', '
            }
        })

        return result
    }

    validateAndGetNextLocation = () => {
        const remainingMandatoryRoles = getRemainingMandatoryRoles(this.connectionTypes, this.props.roles)
        const remainingMandatoryRolesCount = remainingMandatoryRoles.length

        const yourDetailsErrorMessage = 'Sorry! We still need to know the Applicant\'s details.'

        if (remainingMandatoryRolesCount > 0)
        {
            if (this.shouldShowYourDetailsError())
            {
                this.setState({hasError: true, errorText: yourDetailsErrorMessage})
            }
            else
            {
                let errorText =  'Sorry! We still need to know the details for the '+this.mandatoryRolesToString(remainingMandatoryRoles, remainingMandatoryRolesCount)
                this.setState({hasError: true, errorText: errorText+'.'})
            }
        }
        else if (this.isBallparkEstimate() && this.shouldShowYourDetailsError())
        {
            this.setState({hasError: true, errorText: yourDetailsErrorMessage})
        }
        else
        {
            this.props.addProgress({'path': '/roles-hub'})

            if (this.isProject() || this.isSubdivision() || this.isBallparkEstimate())
                return '/manual-capacity'

            const connectionsChecked = getCheckedFields(this.props.connections)
            if (connectionsChecked.length === 1 && connectionsChecked.includes('newSubdivision'))
            {
                const nextNonConnectionService = getNextNonConnectionService(this.props.services, this.props.connections, this.props.historyLocation)
                return nextNonConnectionService !== null ? nextNonConnectionService : '/other-information'
            }

            return '/retailer'
        }

        this.props.removeProgress({'path': '/roles-hub'})
        return false
    }

    validateAndNavigate = (direction) => {
        const location = this.validateAndGetNextLocation()
        if (location)
        {
            this.props.updateLocation(location, null, direction)

            return true
        }

        return false
    }

    getRoleNameAndDescription = (role) => {
        const roleDescription = {
            'Bill Payer': ' - if there is a cost associated with this work then the quote and invoice will be issued to this person.',
            'Retail Account Holder': ' - the person who is billed by the electricity retailer for electricity at the service address.'
        }

        return <><b><MandatoryIndicator>{role}</MandatoryIndicator></b>{typeof roleDescription[role] !== 'undefined' ? roleDescription[role] : null}</>
    }

    getRemainingRolesList = (remainingMandatoryRoles) => {
        const remainingMandatoryRolesCount = remainingMandatoryRoles.length

        if (remainingMandatoryRolesCount === 0)
        {
            return null
        }

        return (
            <ul className='roles-description-list'>
                {
                    remainingMandatoryRoles.map((role, i) => {
                        return <li key={'role_'+i}>{this.getRoleNameAndDescription(role)}</li>
                    })
                }
            </ul>
        )
    }

    getSections = (roles, key) => {
        const showMandatoryIndicator = key === 'mandatory_role'
        const remainingMandatoryRoles = getRemainingMandatoryRoles(this.connectionTypes, this.props.roles)

        return roles.map((role, i) => {
            const contactId = getContactIdByRole(role, this.props.roles)

            const name = getContactNameByContactIdOrCopyFromContact(contactId, this.props.contacts, this.props.roles)
            const roleHasError = this.state.hasError && remainingMandatoryRoles.includes(role)

            let className = 'role-section'
            if (roleHasError)
            {
                className += ' has-error'
            }

            let hideDeleteButton = false

            return <div className={className} key={key+'_'+i}>
                <div className="heading-wrapper">
                    <div className='h2-wrapper'>
                        <h2>{showMandatoryIndicator ? <MandatoryIndicator>{role}</MandatoryIndicator> : role}</h2>
                    </div>
                </div>

                {contactId !== null ?
                    <ContactRow
                        name={name}
                        detailsValidated={!this.shouldShowYourDetailsError()}
                        contactId={contactId}
                        role={role}
                        handleDeleteClick={() => this.handleDeleteClick(contactId)}
                        goToContactDetailsSection={this.goToContactDetailsSection}
                        hideDeleteButton={hideDeleteButton}
                    />
                    :
                    <Button
                        className="center-button"
                        text={"Add " + role + " Details"}
                        btnType="btn-primary"
                        action={() => this.goToContactDetailsSection('/roles-hub/contact-details/new-contact', getNextContactId(this.props.contacts), role)}
                    />
                }

                {roleHasError ?
                    <Alert text={<>Please add your {role}'s Details.</>} type='danger' />
                : null}
            </div>
        })
    }

    getApplicantContactName = () => {
        return this.props.contacts[0].firstName+' '+this.props.contacts[0].lastName
    }

    render() {

        const remainingMandatoryRoles = getRemainingMandatoryRoles(this.connectionTypes, this.props.roles)
        const remainingRolesList = this.getRemainingRolesList(remainingMandatoryRoles)

        const mandatoryRoles = getMandatoryRoles(this.connectionTypes)
        const optionalRoles = getOptionalRoles(this.connectionTypes).filter(role => !mandatoryRoles.includes(role)) // optional roles without duplicating mandatory ones
        const yourDetailsHasError = this.shouldShowYourDetailsError()
        const showYourDetailsHasError = this.state.hasError && yourDetailsHasError // hasError is here so we don't show the error straight away when they visit the section

        return (
            <div id="roles-hub" className="section-margin-bottom">
                <Header
                    title={<>
                        Thanks {this.props.firstName}, let’s find out who’s who
                    </>}
                />

                <Navigation isTop={true} setParentState={this.setParentState} handleNavigation={this.handleNavigation} />

                <Modal
                    closeAction={() => this.setState({showDeleteContact: false})}
                    component={<div>
                        <span>Are you sure you want to delete this contact?</span>
                        <Button text="Confirm deletion" action={this.handleDeleteConfirmClick} />
                    </div>}
                    className='confirm'
                    show={this.state.showDeleteContact}
                    setParentState={this.setParentState}
                />

                {this.state.render ?
                    <div ref={this.props.sectionWrapperRef} className="section">
                        <div className="container single-col">
                            {this.state.hasError ?
                                <div className="validation-top-wrapper">
                                    <Alert text={this.state.errorText} type="danger" />
                                </div>
                            : null}

                            <div className="wrapper">
                                {!yourDetailsHasError ?
                                    <Alert type="description" text={
                                        <div>
                                            {remainingRolesList !== null ?
                                                <>
                                                    <span>We're going to need the contact information of the:</span>
                                                    {remainingRolesList}
                                                    <span>There will be a chance to add the contact information of other people involved in your job too.</span>
                                                </>
                                                :
                                                <span>Thanks! We have all of the contact information we need now, you can move on by selecting Next.</span>
                                            }
                                        </div>
                                    } />
                                : null}

                                <MandatoryText />

                                <div className={showYourDetailsHasError ? 'your-details-section has-error' : 'your-details-section'}>
                                    <div className="heading-wrapper">
                                        <div className='h2-wrapper'>
                                            <h2><MandatoryIndicator>Applicant</MandatoryIndicator></h2>
                                        </div>
                                        <p>We need to know the Applicant's details</p>
                                    </div>

                                    <ContactRow
                                        name={this.getApplicantContactName()}
                                        detailsValidated={!yourDetailsHasError}
                                        contactId="0"
                                        role="Applicant"
                                        type="your-details"
                                        goToContactDetailsSection={this.goToContactDetailsSection}
                                    />

                                    {showYourDetailsHasError ?
                                        <Alert text='Please enter your details.' type="danger" />
                                    : null}
                                </div>
                                {!yourDetailsHasError ?
                                    <>
                                        {mandatoryRoles.length > 0 ?
                                            <div className='role-section-wrapper'>
                                                <h2>Mandatory <MandatoryIndicator>Contacts</MandatoryIndicator></h2>
                                                {this.getSections(mandatoryRoles, 'mandatory_role')}
                                            </div>
                                        : null}

                                        {optionalRoles.length > 0 ?
                                            <div className='role-section-wrapper'>
                                                <h2>Optional Contacts</h2>
                                                {this.getSections(optionalRoles, 'optional_role')}
                                            </div>
                                        : null}
                                    </>
                                : null}

                                {this.state.hasError && this.props.showBottomNavigation ?
                                    <div className='validation-bottom-wrapper'>
                                        <Alert text={this.state.errorText} type="danger" />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                : null}

                {this.props.showBottomNavigation ?
                    <Navigation handleNavigation={this.handleNavigation} />
                : null}
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(
    React.forwardRef((props, ref) => {
        return <RolesHub ref={ref} {...props} />
    })
)
