import doesHaveCopyFromContactId from "./does-have-copy-from-contact-id";
import getAllRoles from "../services/roles/get-all-roles";

export function getContactIdsWithCopyFromContactId(contacts) {
    const contactKeys = Object.keys(contacts)
    return contactKeys.filter(key => {
        const contact = contacts[key]
        return doesHaveCopyFromContactId(contact)
    }).reduce((result, contactId) => {
        result.push(contactId)
        return result
    }, [])
}

export function getUpdatedRoles(originalRoles, originalContacts, contactsWithCopyContactIds) {
    let updatedRoles = {}

    const originalRoleKeys = Object.keys(originalRoles)

    originalRoleKeys.forEach(contactId => {
        const role = originalRoles[contactId][0]
        if (contactsWithCopyContactIds.includes(contactId))
        {
            const copyFromContactId = originalContacts[contactId]['copyFromContactId']

            if (typeof updatedRoles[copyFromContactId] !== 'undefined')
            {
                updatedRoles[copyFromContactId] = [
                    ...updatedRoles[copyFromContactId],
                    role
                ]
            }
            else
            {
                updatedRoles[copyFromContactId] = [
                    role
                ]
            }
        }
        else
        {
            if (typeof updatedRoles[contactId] === 'undefined')
            {
                updatedRoles[contactId] = originalRoles[contactId]
            }
            else
            {
                updatedRoles[contactId] = [
                    ...originalRoles[contactId],
                    ...updatedRoles[contactId]
                ]
            }
        }
    })

    return updatedRoles
}

export function formatContacts(originalContacts, originalRoles) {
    let result = []

    const contactsWithCopyContactIds = getContactIdsWithCopyFromContactId(originalContacts)
    const roles = getUpdatedRoles(originalRoles, originalContacts, contactsWithCopyContactIds)

    const originalContactsKeys = Object.keys(originalContacts)
    const contacts = originalContactsKeys.filter((originalContactId) => {
        return !contactsWithCopyContactIds.includes(originalContactId)
    }).reduce((result, contactId) => {
        result[contactId] = originalContacts[contactId]
        return result
    }, {})

    let atLeastOneContactHasBillPayerRole = false
    Object.keys(roles).forEach((i) => {
        const contactRoles = roles[i]
        if (contactRoles.includes('Bill Payer'))
            atLeastOneContactHasBillPayerRole = true
    })

    // this is applicable to at least ballParkEstimate - where Bill Payer is optional
    // we manually add the Bill Payer role to the main contact, so that the payment PDF receipts is handled correctly
    if (!atLeastOneContactHasBillPayerRole)
        roles[0] = [...roles[0], 'Bill Payer']

    Object.keys(contacts).map((contactId) => {
        const contact = contacts[contactId]

        let contactDetails = {
            firstName: contact.firstName,
            lastName: contact.lastName,
            phoneNumber: contact.phoneNumber,
            mobilePhoneNumber: contact.mobileNumber,
            emailAddress: contact.emailAddress
        }

        if (contact.preferredMethodOfContact) {
            contactDetails.preferredMethodOfContact = contact.preferredMethodOfContact
        }

        if (contact.addressNumber) {
            contactDetails.mailingAddress = {
                number: contact.addressNumber,
                street: contact.addressStreet,
                suburb: contact.addressSuburb,
                city: contact.addressCity,
                postcode: contact.addressPostcode
            }
        }

        if (contact.companyName) {
            contactDetails.companyName = contact.companyName
        }

        if (contact.siteContact) {
            contactDetails.siteContact = contact.siteContact
        }

        contactDetails.roles = roles[contactId]

        result.push(contactDetails)
    })

    return result
}

export function contactPassesFilter(roles, contactId, connectionTypes) {
    return getAllRoles(connectionTypes).includes(roles[contactId][0]);
}

export function getFilteredContacts(contacts, roles, connectionTypes) {

    return Object.keys(contacts).filter(contactId => {
        return contactPassesFilter(roles, contactId, connectionTypes)
    }).reduce((result, contactId) => {
        result[contactId] = contacts[contactId]
        return result
    }, {})
}
