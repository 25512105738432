import getValidationRuleName from "../../helpers/get-validation-rule-name"
import validatePhone from "../../services/validator/validate-phone"
import validateEmail from "../../services/validator/validate-email"
import lettersSpacesHyphens from "../../services/validator/validate-letters-spaces-hyphens"
import validatePostcode from "../../services/validator/validate-post-code"
import validateStreetType from "../../services/validator/validate-street-type"
import validateMaxAlphabeticalChars from "../../services/validator/validate-max-alphabetical-chars"
import validateHyphensForwardSlashAlphanumericSpaces from "./validate-hyphens-forward-slash-alphanumeric-spaces"
import validateUnitNumber from "../../services/validator/validate-unit-number"

const isTruthyAndValid = (value) => {
    // not null and not false and not empty string and either not a number or it's a positive number
    return value !== null && value !== false && value !== '' && (isNaN(value) || value > 0)
}

const getValue = ({data, type, fieldName}) => {

    if (typeof data[type] !== 'undefined' && data[type] !== null && typeof data[type][fieldName] !== 'undefined')
        return data[type][fieldName]

    if (typeof data[fieldName] !== 'undefined')
        return data[fieldName]

    return null
}

const isPositiveNumber = (value) => {
    return !isNaN(value) && value > 0
}

const getBypassRequiredIfRule = (rules) => {
    const ruleValues = Object.values(rules)
    for (let rule of ruleValues) {
        if (rule.bypassRequiredIf !== undefined) {
            return rule.bypassRequiredIf
        }
    }

    return null
}

const sanitizeString = (value) => {
    return value.trim().replace(/\s\s+/g, ' ').trim()
}

const failsMaxRule = (value, max) => {
    const sanitizedValue = sanitizeString(value)

    return sanitizedValue.length > max
}

const failsMaxValueRule = (value, max) => {
    const numericValue = Number(value)

    return !isNaN(numericValue) && numericValue > max
}

const failsMinRule = (value, min) => {
    const sanitizedValue = sanitizeString(value)
    if (sanitizedValue.length === 0 && value.length > 0) // fixes issue where a bunch of spaces are sent through to bypass validation, which then get trimmed on backend via middleware and cause validation errors
        return true

    return sanitizedValue.length > 0 && sanitizedValue.length < min
}

const bypassRequired = (props, rules, type) => {
    const requiredIfRule = getBypassRequiredIfRule(rules)
    if (requiredIfRule === null)
        return false

    // Split by comma to handle multiple conditions
    const conditions = requiredIfRule.split(',')

    // If there are multiple conditions, all must be true to bypass
    if (conditions.length > 1) {
        // Check if ALL conditions are met (using every instead of some)
        return conditions.every(condition => {
            const keyValuePair = condition.split(":")
            if (keyValuePair.length > 1) {
                const key = keyValuePair[0]
                const value = keyValuePair[1]

                if (typeof props[type] !== 'undefined' && props[type] !== null &&
                    typeof props[type][key] !== 'undefined' && props[type][key] !== null &&
                    Array.isArray(props[type][key])) {
                    return props[type][key].includes(value)
                }

                if (typeof props[type] !== 'undefined' && props[type] !== null &&
                    typeof props[type][key] !== 'undefined' && props[type][key] !== null) {
                    return props[type][key] === value
                }

                if (typeof props[key] !== 'undefined' && props[key] !== null) {
                    return props[key] === value
                }

                return false
            }

            // Handle the case where it's just a field name without a value
            const requiredIfFieldValue = getValue({data: props, type: type, fieldName: condition})
            return isTruthyAndValid(requiredIfFieldValue)
        })
    }

    // Original logic for a single condition
    const keyValuePair = requiredIfRule.split(":")
    if (keyValuePair.length > 1) {
        const key = keyValuePair[0]
        const value = keyValuePair[1]

        if (typeof props[type] !== 'undefined' && props[type] !== null && typeof props[type][key] !== 'undefined' && props[type][key] !== null && Array.isArray(props[type][key])) {
            return props[type][key].includes(value)
        }

        if (typeof props[type] !== 'undefined' && props[type] !== null && typeof props[type][key] !== 'undefined' && props[type][key] !== null) {
            return props[type][key] === value
        }

        if (typeof props[key] !== 'undefined' && props[key] !== null) {
            return props[key] === value
        }

        return false
    }

    const requiredIfFieldValue = getValue({data: props, type: type, fieldName: requiredIfRule})

    return isTruthyAndValid(requiredIfFieldValue)
}

const arrayIncludesValue = (array, value) => {
    return array.includes(value)
}

const getValidationResults = (props, field, rules, type, state, setParentState) => {
    let result = {}
    result['field'] = []
    result['global'] = []
    const validationMessages = {}

    rules.forEach((rule) => {
        const value = getValue({data: props, type: type, fieldName: field})

        // Handle bespoke validation rules
        if (typeof rule === 'object' && rule.validate && rule.key) {
            if (!rule.validate(value, props)) {
                result['field'].push(rule.key)
            }
        }

        if (
            typeof rule === 'object' &&
            typeof rule.illegalStrings !== 'undefined' &&
            !arrayIncludesValue(result['field'], 'illegalStrings')
        ) // prevents duplicate requiredIf validation errors
        {
            if (rule.illegalStrings.includes(value))
                result['field'].push('illegalStrings')
        }

        // required if
        else if (
            typeof rule === 'object' &&
            typeof rule.requiredIf !== 'undefined' &&
            !arrayIncludesValue(result['field'], 'requiredIf') && // prevents duplicate requiredIf validation errors
            !bypassRequired(props, rules, type)) {
            const requiredIfSplit = rule.requiredIf.split(":")
            if (requiredIfSplit.length > 1) {
                const key = requiredIfSplit[0]
                const value = requiredIfSplit[1]

                if (props[type][key] === value && !isTruthyAndValid(value)) {
                    result['field'].push('requiredIf')
                }

            }

            if (isTruthyAndValid(getValue({
                data: props,
                type: type,
                fieldName: rule.requiredIf
            })) && !isTruthyAndValid(value)) {
                result['field'].push('requiredIf')
            }
        }

        // required with
        else if (typeof rule === 'object' && rule.requiredWith && !arrayIncludesValue(result['field'], 'requiredWith') && !bypassRequired(props, rules, type)) {
            const requiredWith = rule.requiredWith.split(',')

            if (requiredWith.length) {

                // checks values for each field that's "required with"
                const requireTruthyValidTarget = requiredWith.some(name => {

                    // Accomodate an array of values from the new multi-select component
                    const isValueLikelyMultiSelect = name.includes('.')
                    const isValueLikelySelect = name.includes(':')
                    const splitWith = isValueLikelyMultiSelect ? '.' : ':'

                    let [nestedKey, valueName] = (isValueLikelyMultiSelect || isValueLikelySelect) ? name.split(splitWith) : [type, name]

                    let valueForKey = (isValueLikelyMultiSelect || isValueLikelySelect) ?
                        getValue({data: props[type], fieldName: nestedKey}) : // [nestedKey, valueName] - [type]
                        getValue({data: props, type: type, fieldName: name}) // [type, name] - [onsiteGeneration, generationCapacityKw]

                    // Conclusively test if the target value is an array
                    const isArray = Array.isArray(valueForKey)
                    const requireTruthyValidTarget = valueForKey === valueName

                    // const errorWithValue = isArray ? !valueForKey.includes(valueName) : !isTruthyAndValid(valueForKey)

                    /*if(requireTruthyValidTarget){
                        console.log({
                            type: type,
                            fieldName: field,
                            fieldValue: value,
                            requiredWith: name,
                            nestedKey: nestedKey,
                            valueName: valueName,
                            valueForKey: valueForKey
                        });
                    }*/

                    return requireTruthyValidTarget
                })

                // checks value for this specific field
                if (requireTruthyValidTarget && !isTruthyAndValid(value)) {
                    result['field'].push('requiredWith')
                }

            }
        }

        // required without
        else if (typeof rule === 'object' && !arrayIncludesValue(result['field'], 'requiredWithout') && rule.requiredWithout && !bypassRequired(props, rules, type)) {
            const requiredWithout = rule.requiredWithout.split(',')

            if (requiredWithout.length) {

                // checks values for each field that's "required without"
                const hasError = requiredWithout.some(name => {
                    let [errorType, fieldName] = name.includes('.') ? name.split('.') : [type, name]

                    return !isTruthyAndValid(getValue({data: props, type: errorType, fieldName}))
                })

                // checks value for this specific field
                if (hasError && !isTruthyAndValid(value)) {
                    result['field'].push('requiredWithout')
                }
            }
        } else if (rule === 'required' && !arrayIncludesValue(result['field'], 'required') && !isTruthyAndValid(value) && !bypassRequired(props, rules, type)) {
            result['field'].push('required')
        } else if (rule === 'oneRequired') {
            if (!arrayIncludesValue(result['global'], 'oneRequiredRule'))
                result['global'].push('oneRequiredRule')

            if (isTruthyAndValid(value))
                result['global'].push('oneRequiredRulePassed')
        } else if (rule === 'time' && isTruthyAndValid(value)) // note hasError is set inside function
        {
            if (!arrayIncludesValue(result['field'], 'time-hour') && (typeof value.hour === 'undefined' || value.hour === null)) {
                result['field'].push('time-hour')
            }

            if (!arrayIncludesValue(result['field'], 'time-minute') && (typeof value.minute === 'undefined' || value.minute === null)) {
                result['field'].push('time-minute')
            }
        } else if (getValidationRuleName(rule) === 'min' && !arrayIncludesValue(result['field'], 'min') && typeof value.length !== 'undefined' && failsMinRule(value, rule.min)) {
            result['field'].push('min')
        } else if (getValidationRuleName(rule) === 'max' && !arrayIncludesValue(result['field'], 'max') && typeof value.length !== 'undefined' && failsMaxRule(value, rule.max)) {
            result['field'].push('max')
        } else if (getValidationRuleName(rule) === 'maxValue' && !arrayIncludesValue(result['field'], 'maxValue') && typeof value.length !== 'undefined' && failsMaxValueRule(value, rule.maxValue)) {
            result['field'].push('maxValue')
        } else if (getValidationRuleName(rule) === 'maxNumericChars' && !arrayIncludesValue(result['field'], 'maxNumericChars') && typeof value.length !== 'undefined') {
            if (value.replace(/[^0-9]/g, '').length > rule.maxNumericChars)
                result['field'].push('maxNumericChars')
        } else if (getValidationRuleName(rule) === 'nonNumeric' && !arrayIncludesValue(result['field'], 'nonNumeric') && typeof value.length !== 'undefined') {
            if (rules.includes('nonNumericBypassIfHighway') && value.toLowerCase().includes('highway'))
                return

            if (value.replace(/[0-9]/g, '').length !== value.length)
                result['field'].push('nonNumeric')
        } else if (getValidationRuleName(rule) === 'maxAlphabeticalChars' && !arrayIncludesValue(result['field'], 'maxAlphabeticalChars') && typeof value.length !== 'undefined' && !validateMaxAlphabeticalChars(value, rule.maxAlphabeticalChars)) {
            result['field'].push('maxAlphabeticalChars')
        }

        /*
            Specific Unit validation rules
            Single digits (1, 2, 3...)
            Multiple digits up to 3 (123, 45...)
            Digit(s) + letter (1A, 123B...)
            Letter + digit(s) (A1, B123...)
         * */
        else if (getValidationRuleName(rule) === 'unitNumber' && !arrayIncludesValue(result['field'], 'unitNumber') && typeof value.length !== 'undefined' && !validateUnitNumber(value, rule.unitNumber)) {
            result['field'].push('unitNumber')
        } else if (getValidationRuleName(rule) === 'minNumber' && !arrayIncludesValue(result['field'], 'minNumber') && typeof value.length !== 'undefined' && !isNaN(value) && value < rule.minNumber) {
            result['field'].push('minNumber')
        } else if (getValidationRuleName(rule) === 'maxNumber' && !arrayIncludesValue(result['field'], 'maxNumber') && typeof value.length !== 'undefined' && !isNaN(value) && value > rule.maxNumber) {
            result['field'].push('maxNumber')
        }
        // don't use isTruthyAndValid in numeric validation because it will fail before reaching the isPositiveNumber validation, due to the number check in it
        else if (rule === 'numeric' && !arrayIncludesValue(result['field'], 'numeric') && typeof value.length !== 'undefined' && value.length > 0 && !isPositiveNumber(value)) {
            result['field'].push('numeric')
        } else if (rule === 'phoneNumber' && !arrayIncludesValue(result['field'], 'phoneNumber') && isTruthyAndValid(value) && !validatePhone(value)) {
            result['field'].push('phoneNumber')
        } else if (rule === 'emailAddress' && !arrayIncludesValue(result['field'], 'emailAddress') && isTruthyAndValid(value) && !validateEmail(value)) {
            result['field'].push('emailAddress')
        } else if (rule === 'lettersSpacesHyphens' && !arrayIncludesValue(result['field'], 'lettersSpacesHyphens') && isTruthyAndValid(value) && !lettersSpacesHyphens(value)) {
            result['field'].push('lettersSpacesHyphens')
        } else if (rule === 'postcode' && !arrayIncludesValue(result['field'], 'postcode') && isTruthyAndValid(value) && !validatePostcode(value)) {
            result['field'].push('postcode')
        } else if (rule === 'streetType' && !arrayIncludesValue(result['field'], 'streetType') && isTruthyAndValid(value) && !validateStreetType(value)) {
            result['field'].push('streetType')
        } else if (rule === 'allowOnlyHyphensForwardSlashAlphaNumericSpaces' && !arrayIncludesValue(result['field'], 'allowOnlyHyphensForwardSlashAlphaNumericSpaces') && isTruthyAndValid(value) && !validateHyphensForwardSlashAlphanumericSpaces(value)) {
            result['field'].push('allowOnlyHyphensForwardSlashAlphaNumericSpaces')
        }
    })

    return result
}

const validateData = (props, rules, type, state, setParentState) => {
    let result = {
        'field': [],
        'global': []
    }

    Object.keys(rules).forEach((field) => {
        const fieldRules = rules[field]
        const validatedResults = getValidationResults(props, field, fieldRules, type, state, setParentState)
        const validatedResultsField = validatedResults['field']
        const validatedResultsGlobal = validatedResults['global']

        if (validatedResultsField.length > 0) {
            result['field'][field] = validatedResultsField
        }

        if (validatedResultsGlobal.includes('oneRequiredRule') && !result['global'].includes('oneRequired')) {
            result['global'].push('oneRequired') // indicates that the oneRequiredRule does exist
        }

        if (validatedResultsGlobal.includes('oneRequiredRulePassed') && !result['global'].includes('oneRequiredRulePassed')) {
            result['global'].push('oneRequiredRulePassed') // indicates that while the oneRequiredRule exists, validation will pass
        }
    })

    return result
}

const startValidation = (props, rules, state, setParentState, type = null) => {
    let result = {}
    result.global = []
    result.field = []

    const validationResults = validateData(props, rules, type, state, setParentState)

    const validationResultsField = validationResults['field']
    const validationResultsGlobal = validationResults['global']

    // update global errors
    if (validationResultsGlobal.length > 0) {
        result.global.push(...validationResultsGlobal)
    }

    // update field errors
    const validationResultsFieldObjectKeys = Object.keys(validationResultsField)
    if (validationResultsFieldObjectKeys.length > 0) {
        validationResultsFieldObjectKeys.forEach((field) => {
            const rules = validationResultsField[field]
            const errorState = type === null ? field + 'Errors' : type + '_' + field + 'Errors'
            result.field.push({[errorState]: rules})
        })
    }

    if (result.field.length === 0 && result.global.length === 0)
        return null

    if (process.env.NODE_ENV === 'development') {
        console.log('Validation Type:', type)
        console.log('Validation Results:', {
            field: result.field,
            global: result.global
        })
    }
    return result
}

export const validator = ({props, state, setParentState, validationRules, validationErrorFields}) => {
    if (!validationRules)
        return true

    let results = {
        global: [],
        fields: {}
    }

    const validationResults = Object.keys(validationRules).map((type) => {
        let rule = validationRules[type]

        if (Array.isArray(validationRules[type]))
            return startValidation(props, {[type]: rule}, state, setParentState)

        return startValidation(props, rule, state, setParentState, type)
    })

    validationResults
        .filter(validationResult => validationResult !== null)
        .forEach((validationResult) => {
            validationResult.field.forEach((fieldObject) => {
                Object.keys(fieldObject).forEach((fieldName) => {
                    results.fields[fieldName] = fieldObject[fieldName]
                })
            })

            validationResult.global.forEach((globalName) => {
                if (!results.global.includes(globalName)) {
                    results.global.push(globalName)
                }
            })
        })

    if (results.global.includes('oneRequiredRulePassed')) {
        results.global = results.global.filter((value) => value !== 'oneRequired' && value !== 'oneRequiredRulePassed')
    }

    const oneRequiredError = results.global.includes('oneRequired')
    let updatedValidationErrors = {oneRequiredError}

    const fieldNames = Object.keys(results.fields)
    if (fieldNames.length > 0) {
        updatedValidationErrors = {...updatedValidationErrors, ...results.fields}
    }

    const validationErrorState = {...validationErrorFields, ...updatedValidationErrors}

    setParentState(validationErrorState)

    return fieldNames.length === 0 && !oneRequiredError
}

export default validator
