import React from "react"
import ReactDOM from "react-dom/client"
import App from "./components/app"
import configureStore from './store/create-store'

const store = configureStore()

__webpack_public_path__ = "http://localhost:8080/"

if (window.browserIsSupported) {
    console.info('Version: '+process.appVersion)

    // Create root once and store it
    const rootElement = document.getElementById("app")
    const root = ReactDOM.createRoot(rootElement)
    root.render(<App store={store} />)

    if (module.hot) {
        module.hot.accept('./components/app', () => {
            const NextApp = require('./components/app').default
            // Use existing root instance
            root.render(<NextApp store={store} />)
        })
    }
} else {
    document.body.style.overflowY = "auto"
}
